import BaseRestClient from '@/assets/js/api/BaseRestClient'

class CoreCommissionApiClient extends BaseRestClient {
    constructor(baseURL, errorCallback, successCallback) {
        super(baseURL, "CoreCommissionApiClient", errorCallback, successCallback);
    }

    getContracts(domain, contractorId, params = {}) {
        let query = ""
        if (contractorId) query += "contractorId=" + contractorId;
        return this.request("get", {
            url: "/commissionContract",
            domain,
            query,
            ...params,
        })
    }

    upsertContract(domain, contract, params = {}) {
        return this.request("put", {
            url: "/commissionContract/" + contract.id,
            domain,
            body: contract,
            successMsg: "Contract " + contract.id + " updated",
            ...params
        })
    }

    deleteContract(domain, contractId, params = {}) {
        return this.request("delete", {
            url: "/commissionContract/" + contractId,
            domain,
            successMsg: "Contract " + contractId + " deleted",
            ...params
        })
    }

    getContractItems(domain, contractId, params = {}) {
        return this.request("get", {
            url: "/commissionContract/" + contractId + "/item",
            domain,
            ...params,
        })
    }

    upsertContractItem(domain, contractId, contractItem, params = {}) {
        return this.request("put", {
            url: "/commissionContract/" + contractId + "/item/" + contractItem.id,
            domain,
            body: contractItem,
            ...params,
        });
    }

    deleteContractItem(domain, contractId, contractItemId, params = {}) {
        return this.request("delete", {
            url: "/commissionContract/" + contractId + "/item/" + contractItemId,
            domain,
            ...params,
        });
    }
}

export default CoreCommissionApiClient;