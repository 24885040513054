import BaseRestClient from '@/assets/js/api/BaseRestClient'

class CoreCacheApiClient extends BaseRestClient {
    constructor(baseURL, errorCallback, successCallback) {
        super(baseURL, "CoreCacheApiClient", errorCallback, successCallback);
    }

    async invalidate(domain, objectTag, params = {}) {

        return this.request("post", {
            domain,
            url: "/cache/invalidate",
            body: { objectTag },
            successMsg: "Invalidated " + (objectTag === "all" ? " <strong>all</strong> caches" : "<strong>" + objectTag + "</strong> cache"),
            ...params
        })
    }
}

export default CoreCacheApiClient