import BaseRestClient from '@/assets/js/api/BaseRestClient'
import B2BResellerApiClient from '@/assets/js/api/B2bResellerApiClient'

class B2BApiClient extends BaseRestClient {
  constructor(baseURL, errorCallback, successCallback) {
    super(baseURL, "B2BApiClient", errorCallback, successCallback);
  }
  // eslint-disable-next-line
  b2bResellerApi = new B2BResellerApiClient(
    // eslint-disable-next-line
    BASE_CONFIGURATION.businessLogicDomain,
    this.errorCallback,
    this.successCallback,
  );
}
export default B2BApiClient
