<template>
  <v-dialog v-model="dialog" :width="width" persistent class="confirm-dialog">
    <v-card class="confirm-dialog-card" data-test-id="confirmDialog">
      <v-card-title>
        <v-icon left>mdi-alert</v-icon>
        {{ title }}
      </v-card-title>
      <v-card-text v-html="displayedText" />
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          color="primary"
          @click="confirm(true)"
          data-test-id="confirmYesBtn"
        >
          {{ confirmBtnText }}
        </v-btn>
        <v-btn text @click="confirm(false)" data-test-id="confirmCancelBtn">
          {{ cancelBtnText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import vuetify from "@/plugins/vuetify";

export default {
  vuetify,

  props: {
    title: {
      type: String,
      required: true,
    },

    //The title of the dialog
    text: {
      type: String,
      required: false,
    },

    //dialog width
    width: {
      type: Number,
      required: false,
      default: 400,
    },

    //determines which test is shown in the CONFIRM button
    confirmBtnText: {
      type: String,
      required: false,
      default: "CONFIRM",
    },

    //determined which text is shown in the CANCEL button
    cancelBtnText: {
      type: String,
      required: false,
      default: "CANCEL",
    },
  },

  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
    };
  },

  methods: {
    open() {
      this.dialog = true;
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },

    confirm(confirmed) {
      this.resolve(confirmed);
      this.dialog = false;
    },
  },

  computed: {
    displayedText() {
      return this.$sanitize(this.text, {
        FORBID_TAGS: ["style", "input", "textarea", "button", "form"],
        FORBID_ATTR: ["style", "class"],
      });
    },
  },
};
</script>