import Vuex from 'vuex'
import Vue from 'vue'
import CoreApiClient from '@/assets/js/api/CoreApiClient';
import B2BApiClient from '@/assets/js/api/B2bApiClient';
import ElasticSearchApiClient from '@/assets/js/api/ElasticSearchApiClient';
import localizationModule from "./modules/localization";
import updateToken from "../assets/js/utilities/keycloak/update-token"


//load Vuex
Vue.use(Vuex);

//------ API Clients --------

Vuex.Store.prototype.$coreApi = new CoreApiClient(
    // eslint-disable-next-line
    BASE_CONFIGURATION.coreDomain,
    msg => store.dispatch('setError', msg),
    msg => store.dispatch('setSuccess', msg),
);

Vuex.Store.prototype.$b2bApi = new B2BApiClient(
    // eslint-disable-next-line
    BASE_CONFIGURATION.businessLogicDomain,
    msg => store.dispatch('setError', msg),
    msg => store.dispatch('setSuccess', msg),
);

Vuex.Store.prototype.$esApi = new ElasticSearchApiClient(
    // eslint-disable-next-line
    BASE_CONFIGURATION.elasticSearchDomain,
    msg => store.dispatch('setError', msg),
    msg => store.dispatch('setSuccess', msg),
);


//------- STORE ----------

// eslint-disable-next-line
const coreURL = BASE_CONFIGURATION.coreDomain;

//to handle state
const state = {
    domains: [],
    selectedDomain: null,
    errorMessages: [],
    successMessages: [],
}

//to handle state
const getters = {
    domainPath: state => {
        return `${coreURL}` + "/" + state.selectedDomain
    }
}

//to handle actions
const actions = {
    async loadDomains({ commit }) {
        try {
            const response = await Vuex.Store.prototype.$coreApi.coreDomainApi.getDomains();
            const domains = Array.isArray(response) ? response : [];
            commit("SET_DOMAINS", domains);
        } catch (e) {
            commit("SET_ERROR", e);
        }
    },

    /**
     * @deprecated
     * @param {String} url url to different api (core, bl)
     * @param {String} absolutePath API path without restriction to a specific domain
     * @param {String} path API path which is restricted to the currently selected domain
     * @param {String} query The query parameters of the API request. Must be written without "?" e.g. param1=val1&param2=val2
     * @param {Boolean} returnErrors If true the method returns the request object even if errors occur. The errors are not propagated.
     * @param {Array} suppressedErrors  An array of HTTP error status codes whose error messages should be suppressed. Returns null.
     * @returns The JSON response of the GET request
     */


    async get({ state, commit }, { url, domain, absolutePath, path, query, returnErrors, suppressedErrors }) {
        const token = await updateToken();
        const headers = {
            ["Authorization"]: "Bearer " + token
        }
        if (!url) url = coreURL;
        if (!domain) domain = state.selectedDomain;

        try {
            let requestPath = absolutePath ? (`${url}` + absolutePath) : (`${url}` + "/" + domain) + path;
            let res = await fetch(requestPath + (query ? "?" + query : ""), {
                method: "GET",
                headers
            });

            if (!res.ok) {
                if (returnErrors) return res;
                if (suppressedErrors?.includes(res.status)) return null;
                if (res.status === 500) throw Error(res.statusText);
                let error = await res.json();
                throw Error(error.message);
            }

            return await res.json();
        } catch (e) {
            commit("SET_ERROR", e);
        }

    },

    /**
     * @deprecated
     * @param {String} absolutePath API path without restriction to a specific domain
     * @param {String} path API path which is restricted to the currently selected domain
     * @param {String} query The query parameters of the API request. Must be written without "?" e.g. param1=val1&param2=val2
     * @param {Boolean} returnErrors If true the method returns the request object even if errors occur. The errors are not propagated.
     * @param {Array} suppressedErrors  An array of HTTP error status codes whose error messages should be suppressed. Returns request object
     * @returns The request object
     */
    async delete({ commit }, { url, domain, absolutePath, path, query, successMsg, returnErrors, suppressedErrors }) {
        const token = await updateToken();
        const headers = {
            ["Authorization"]: "Bearer " + token
        }
        if (!url) url = coreURL;
        if (!domain) domain = state.selectedDomain;
        try {
            let requestPath = absolutePath ? (`${url}` + absolutePath) : (`${url}` + "/" + domain) + path;
            let res = await fetch(requestPath + (query ? "?" + query : ""), {
                method: "DELETE",
                headers
            });

            if (!res.ok) {
                if (returnErrors || suppressedErrors?.includes(res.status)) return res;
                if (res.status === 500) throw Error(res.statusText);
                let error = await res.json();
                throw Error(error.message);
            }
            else {
                commit("SET_SUCCESS", successMsg);
            }

            return res;
        } catch (e) {
            commit("SET_ERROR", e);
        }
    },

    /**
     * @deprecated
     * @param {String} absolutePath API path without restriction to a specific domain
     * @param {String} path API path which is restricted to the currently selected domain
     * @param {String} query The query parameters of the API request. Must be written without "?" e.g. param1=val1&param2=val2
     * @param {Boolean} returnErrors If true the method returns the request object even if errors occur. The errors are not propagated.
     * @param {Array} suppressedErrors  An array of HTTP error status codes whose error messages should be suppressed. Returns request object
     * @returns The request object
     */
    async put({ commit }, { url, domain, absolutePath, path, query, body, successMsg, returnErrors, suppressedErrors, msgShowTime }) {
        const token = await updateToken();
        const headers = {
            'Content-Type': 'application/json',
            ["Authorization"]: "Bearer " + token
        }
        if (!url) url = coreURL;
        if (!domain) domain = state.selectedDomain;

        try {
            let requestPath = absolutePath ? (`${url}` + absolutePath) : (`${url}` + "/" + domain) + path;
            let res = await fetch(requestPath + (query ? "?" + query : ""), {
                method: "PUT",
                headers,
                body: JSON.stringify(body)
            });

            if (!res.ok) {
                if (returnErrors || suppressedErrors?.includes(res.status)) return res;
                if (res.status === 500) throw Error(res.statusText);
                let error = await res.json();
                throw Error(error.message);
            }
            else {
                commit("SET_SUCCESS", { message: successMsg, msgShowTime });
            }
            return res;

        } catch (e) {
            commit("SET_ERROR", e, msgShowTime);
        }
    },

    /**
     * @deprecated
     * @param {String} absolutePath API path without restriction to a specific domain
     * @param {String} path API path which is restricted to the currently selected domain
     * @param {String} query The query parameters of the API request. Must be written without "?" e.g. param1=val1&param2=val2
     * @param {Boolean} returnErrors If true the method returns the request object even if errors occur. The errors are not propagated.
     * @param {Array} suppressedErrors  An array of HTTP error status codes whose error messages should be suppressed. Returns request object
     * @returns The request object
     */
    async post({ commit }, { url, domain, absolutePath, path, query, body, successMsg, returnErrors, suppressedErrors }) {
        const token = await updateToken();
        const headers = {
            'Content-Type': 'application/json',
            ["Authorization"]: "Bearer " + token
        }
        if (!url) url = coreURL;
        if (!domain) domain = state.selectedDomain;

        try {
            let requestPath = absolutePath ? (`${url}` + absolutePath) : (`${url}` + "/" + domain) + path;
            let res = await fetch(requestPath + (query ? "?" + query : ""), {
                method: "POST",
                headers,
                body: JSON.stringify(body)
            });

            if (!res.ok) {
                if (returnErrors || suppressedErrors?.includes(res.status)) return res;
                if (res.status === 500) throw Error(res.statusText);
                let error = await res.json();
                throw Error(error.message);
            }
            else {
                commit("SET_SUCCESS", successMsg);
            }

            return res;

        } catch (e) {
            commit("SET_ERROR", e);
        }
    },

    /**
     * @deprecated
     * @param {String} absolutePath API path without restriction to a specific domain
     * @param {String} path API path which is restricted to the currently selected domain
     * @param {String} query The query parameters of the API request. Must be written without "?" e.g. param1=val1&param2=val2
     * @param {Boolean} returnErrors If true the method returns the request object even if errors occur. The errors are not propagated.
     * @param {Array} suppressedErrors  An array of HTTP error status codes whose error messages should be suppressed. Returns request object
     * @returns The request object
     */
    async patch({ getters, commit }, { absolutePath, path, query, body, successMsg, returnErrors, suppressedErrors }) {
        const token = await updateToken();
        const headers = {
            'Content-Type': 'application/json',
            ["Authorization"]: "Bearer " + token
        }
        try {
            let requestPath = absolutePath ? `${coreURL}` + absolutePath : getters.domainPath + path;
            let res = await fetch(requestPath + (query ? "?" + query : ""), {
                method: "PATCH",
                headers,
                body: JSON.stringify(body)
            });

            if (!res.ok) {
                if (returnErrors || suppressedErrors?.includes(res.status)) return res;
                if (res.status === 500) throw Error(res.statusText);
                let error = await res.json();
                throw Error(error.message);
            }
            else {
                commit("SET_SUCCESS", successMsg);
            }

            return res;

        } catch (e) {
            commit("SET_ERROR", e);
        }
    },

    setSuccess(context, message) {
        context.commit("SET_SUCCESS", message);
    },

    setError(context, message) {
        context.commit("SET_ERROR", message);
    }
}

//to handle mutations
const mutations = {

    SET_DOMAINS(state, domains) {
        state.domains = domains;
    },

    SET_SELECTED_DOMAIN(state, domain) {
        state.selectedDomain = domain;
    },

    SET_ERROR(state, message) {
        //check if error is undefined or empty and if so, display default message. 
        if (!message) message = "An unexpected error occurred";
        state.errorMessages.push({
            message: message instanceof Object ? message.message : message,
            date: new Date(),
            msgShowTime: message.msgShowTime
        })
    },

    DISMISS_ERROR(state, index) {
        state.errorMessages.splice(index, 1);
    },

    SET_SUCCESS(state, message) {
        //same handling as in SET_ERROR mutation
        //except that null as value prevents the display of a message
        if (message === null) return;
        if (!message) message = "Operation succeeded";
        state.successMessages.push({
            message: message instanceof Object ? message.message : message,
            date: new Date(),
            msgShowTime: message.msgShowTime
        });
    },

    DISMISS_SUCCESS(state, index) {
        state.successMessages.splice(index, 1)
    }
}

const store = new Vuex.Store({
    state,
    getters,
    actions,
    mutations
});

//------- STORE MODULES --------
store.registerModule('localization', localizationModule);

export default store
