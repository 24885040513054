import httpVueLoader from "@/assets/js/utilities/http-vue-loader"
import updateToken from "@/assets/js/utilities/keycloak/update-token"

//override httpVueLoader.httpRequest function to use the fetch API and introduce error handling
httpVueLoader.httpRequest = async function (url) {
    const token = await updateToken();
    const headers = {
        'Content-Type': 'text/plain',
        'Authorization': "Bearer " + token,
    }
    const response = await fetch(url, {
        method: "GET",
        headers,
    });
    if (response.ok) {
        return await response.text();
    }

    const status = response?.status;
    let error;
    try {
        //Try to parse the error
        error = await response.json();
    } catch (e) {
        throw Error(status + " - External component could not be loaded");
    }
    throw Error(status + " - " + (error?.message ?? "External component could not be loaded"));
};

//override httpVueLoader.load function to implement error handling, if a component cannot be loaded
const originalLoad = httpVueLoader.load;
httpVueLoader.load = async function (url, name) {
    try {
        const res = originalLoad.call(this, url, name);
        if (res.call) {
            return res.call().catch(err => {
                return Promise.reject(err);
            });
        }
        return res;
    } catch (e) {
        return Promise.reject(e);
    }
};

const plugin = {
    install(Vue) {

        /**
         * Loads a Vue component from the given URL.
         * @param {String} url 
         * @returns The parsed component
         */
        Vue.prototype.$loadExternalComponent = async function (url) {
            return httpVueLoader(url).catch(error => {
                throw Error(error?.message ?? error);
            });
        }
    }
};

export default plugin