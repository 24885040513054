import BaseRestClient from '@/assets/js/api/BaseRestClient'

class CoreLocalizationApiClient extends BaseRestClient {
    constructor(baseURL, errorCallback, successCallback) {
        super(baseURL, "CoreLocalizationApiClient", errorCallback, successCallback);
    }

    async getLocalizations(domain, body, signal) {
        const response = await this.request("post", {
            url: "/lookup/localization",
            domain,
            body,
            signal,
        });
        if (response.aborted) return response;
        let localizationData = [];
        if (response?.ok) localizationData = await response.json();
        return localizationData;
    }

    async getLocalizationsByKey(domain, i18nKey, refKeySpace, refKey) {
        let queryParams = [];
        if (refKeySpace) queryParams.push("refKeySpace=" + refKeySpace);
        if (refKey) queryParams.push("refKey=" + refKey);
        const query = queryParams.length > 0 ? queryParams.join("&") : null;
        return this.request("get", {
            url: "/localization/" + i18nKey,
            domain,
            query
        })
    }

    async getLocalizationKeySpaces(domain) {
        return this.request("get", {
            url: "/lookup/localization/keySpace",
            domain
        });
    }

    async patchLocalizations(domain, localizations, keySpace, key) {
        const url = "/localization" + (keySpace ? "/" + keySpace + "/" + key : "");
        return this.request("patch", {
            url,
            domain,
            body: localizations,
        })
    }

    async deleteLocalization(domain, keySpace, key, i18nKeys) {
        const query = "i18nKeys=" + i18nKeys.join("&i18nKeys=");
        return this.request("delete", {
            url: "/localization/" + keySpace + "/" + key,
            query,
            domain,
        });
    }

}

export default CoreLocalizationApiClient;