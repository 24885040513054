<template>
  <v-card :flat="flat" ref="dialogForm" class="dialog-form">
    <v-toolbar v-if="!hideToolbar" flat>
      <slot name="title" :loading="loading">
        <v-toolbar-title data-test-id="dialogFormTitle">{{
          title
        }}</v-toolbar-title>
      </slot>
      <v-divider vertical inset class="mx-4"></v-divider>
      <v-spacer></v-spacer>
      <div v-if="loading" class="px-4">
        <v-progress-circular
          indeterminate
          :size="24"
          :width="2"
          color="grey darken-3"
        />
      </div>
      <slot name="actions" :loading="loading">
        <v-btn
          color="green"
          :data-test-id="saveBtnTestId"
          :disabled="loading"
          @click="$emit('save')"
        >
          {{ saveBtnLabel }}
        </v-btn>
        <v-btn
          text
          class="close-dialog-btn"
          :data-test-id="closeBtnTestId"
          :disabled="loading"
          @click="$emit('close', false)"
        >
          {{ closeBtnLabel }}
        </v-btn>
      </slot>
    </v-toolbar>
    <div class="dialog-form-input-container">
      <slot name="form" :loading="loading" />
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: "Create Item",
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    saveBtnLabel: {
      type: String,
      required: false,
      default: "Save",
    },

    closeBtnLabel: {
      type: String,
      required: false,
      default: "Close",
    },

    saveBtnTestId: {
      type: String,
      required: false,
      default: "dialogSaveBtn",
    },

    closeBtnTestId: {
      type: String,
      required: false,
      default: "dialogCloseBtn",
    },

    flat: {
      type: Boolean,
      required: false,
      default: false,
    },

    fixedHeader: {
      type: Boolean,
      required: false,
      default: false,
    },

    hideToolbar: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  mounted() {
    this.$nextTick(() => {
      if (this.fixedHeader) {
        /* 
          Following logic is needed for highlighting the sticky toolbar on scroll.
          Works in conjunction with the CSS styles at the end of the file.

          1. Create pseudo element and insert it as first child of editor, 
              so that logic for detection of scroll below works 
          */
        const dialogFormEl = this.$refs.dialogForm.$el;
        const stickyElm = document.createElement("div");
        stickyElm.style.cssText = "position:sticky;height:1px;top:-1px";
        const toolbar = dialogFormEl.querySelector(".v-toolbar");
        toolbar.classList.add("fixed-header");
        dialogFormEl.insertBefore(stickyElm, dialogFormEl.firstChild);

        //2. Add intersection observer to detect when the element is intersected by its parent
        //https://css-tricks.com/how-to-detect-when-a-sticky-element-gets-pinned/
        const observer = new IntersectionObserver(
          ([e]) => {
            //if the stickyElm is intersected, elevate the toolbar
            toolbar.classList.toggle("elevation-6", e.intersectionRatio < 1);
          },
          { threshold: [1] }
        );
        observer.observe(stickyElm);
      }
    });
  },
};
</script>

<style scoped>
.dialog-form {
  position: relative;
}

.dialog-form > .v-toolbar.fixed-header {
  position: sticky;
  top: 48px;
  z-index: 1;
}

.dialog-form > .dialog-form-input-container {
  padding: 12px;
}
</style>