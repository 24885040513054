import BaseRestClient from '@/assets/js/api/BaseRestClient'
import CoreDomainApiClient from "@/assets/js/api/CoreDomainApiClient.js"
import CoreLocalizationApiClient from "@/assets/js/api/CoreLocalizationApiClient"
import CoreConfigurationApiClient from "@/assets/js/api/CoreConfigurationApiClient"
import CoreLoggingApiClient from "@/assets/js/api/CoreLoggingApiClient"
import CoreCatalogApiClient from "@/assets/js/api/CoreCatalogApiClient"
import CoreOrderApiClient from "@/assets/js/api/CoreOrderApiClient"
import CoreContractApiClient from "@/assets/js/api/CoreContractApiClient.js"
import CoreConnectorApiClient from '@/assets/js/api/CoreConnectorApiClient'
import CoreCustomerApiClient from "@/assets/js/api/CoreCustomerApiClient"
import CoreCartRuleApiClient from "@/assets/js/api/CoreCartRuleApiClient"
import CoreAdminApiClient from "@/assets/js/api/CoreAdminApiClient"
import CoreCommissionApiClient from "@/assets/js/api/CoreCommissionApiClient"
import CoreServiceProviderApiClient from "@/assets/js/api/CoreServiceProviderApiClient.js"
import CoreCacheApiClient from '@/assets/js/api/CoreCacheApiClient.js'
import CoreElasticSearchApiClient from "@/assets/js/api/CoreElasticSearchApiClient.js"
import CoreUserApiClient from "@/assets/js/api/CoreUserApiClient.js"

class CoreApiClient extends BaseRestClient {
    constructor(baseURL, errorCallback, successCallback) {
        super(baseURL, "CoreApiClient", errorCallback, successCallback);
    }

    coreDomainApi = new CoreDomainApiClient(
        // eslint-disable-next-line
        this.baseURL,
        this.errorCallback,
        this.successCallback,
    );

    // eslint-disable-next-line
    coreLocalizationApi = new CoreLocalizationApiClient(
        // eslint-disable-next-line
        this.baseURL,
        this.errorCallback,
        this.successCallback,
    );

    // eslint-disable-next-line
    coreConfigurationApi = new CoreConfigurationApiClient(
        // eslint-disable-next-line
        this.baseURL,
        this.errorCallback,
        this.successCallback,
    );

    coreLoggingApi = new CoreLoggingApiClient(
        // eslint-disable-next-line
        this.baseURL,
        this.errorCallback,
        this.successCallback,
    );

    coreCatalogApi = new CoreCatalogApiClient(
        // eslint-disable-next-line
        this.baseURL,
        this.errorCallback,
        this.successCallback,
    );

    coreCustomerApi = new CoreCustomerApiClient(
        // eslint-disable-next-line
        this.baseURL,
        this.errorCallback,
        this.successCallback,
    );

    coreOrderApi = new CoreOrderApiClient(
        // eslint-disable-next-line
        this.baseURL,
        this.errorCallback,
        this.successCallback,
    );

    coreCartRuleApi = new CoreCartRuleApiClient(
        // eslint-disable-next-line
        this.baseURL,
        this.errorCallback,
        this.successCallback,
    );

    coreConnectorApi = new CoreConnectorApiClient(
        // eslint-disable-next-line
        this.baseURL,
        this.errorCallback,
        this.successCallback,
    );

    coreAdminApi = new CoreAdminApiClient(
        // eslint-disable-next-line
        this.baseURL,
        this.errorCallback,
        this.successCallback,
    );

    coreContractApi = new CoreContractApiClient(
        // eslint-disable-next-line
        this.baseURL,
        this.errorCallback,
        this.successCallback,
    );

    coreCommissionApi = new CoreCommissionApiClient(
        // eslint-disable-next-line
        this.baseURL,
        this.errorCallback,
        this.successCallback,
    );

    coreServiceProviderApi = new CoreServiceProviderApiClient(
        // eslint-disable-next-line
        this.baseURL,
        this.errorCallback,
        this.successCallback,
    );

    coreCacheApi = new CoreCacheApiClient(
        // eslint-disable-next-line
        this.baseURL,
        this.errorCallback,
        this.successCallback,
    );

    coreElasticSearchApi = new CoreElasticSearchApiClient(
        // eslint-disable-next-line
        this.baseURL,
        this.errorCallback,
        this.successCallback,
    );

    coreUserApi = new CoreUserApiClient(
        // eslint-disable-next-line
        this.baseURL,
        this.errorCallback,
        this.successCallback,
    );

}
export default CoreApiClient
