import BaseRestClient from '@/assets/js/api/BaseRestClient'

class B2BResellerApiClient extends BaseRestClient {
  constructor(baseURL, errorCallback, successCallback) {
    super(baseURL, "B2BResellerApiClient", errorCallback, successCallback);
  }

  /* ---------  RESELLER  -------------  */
  async getResellers(domain, filters, currentPage, limit) {
    const offset =
      limit * (currentPage > 0 ? currentPage - 1 : 0);
    let query = "includeSubdomains=true&" +
      "total=true&offset=" +
      offset +
      "&limit=" +
      limit;
    //set filters
    for (let index in filters) {
      let value = filters[index].value;
      let property = filters[index].property;
      if (value) {
        query += "&" + property + "=" + value;
      }
    }
    const resellerData = await this.request('get', {
      url: "/b2bapi/admin/reseller",
      domain,
      query
    }
    );

    return resellerData ? resellerData : null
  }


  async createReseller(domain, reseller) {
    return await this.request("post", {
      domain,
      url: "/b2bapi/admin/reseller",
      body: reseller,
      successMsg: "Reseller " + reseller.name + " created",
    });
  }

  async upsertReseller(reseller) {
    const options = {
      domain: reseller.domain,
      url: "/b2bapi/admin/reseller/" + reseller.id,
      body: reseller,
      successMsg: "Reseller " + reseller.name + " (" + reseller.id + ") updated",
    };
    return await this.request("put", options);
  }

  async loadReseller(domain, resellerId) {
    const options = {
      domain: domain,
      url: "/b2bapi/admin/reseller/" + resellerId,
    };
    return await this.request("get", options);
  }

  /* ---------  RESELLER USER -------------  */

  async getUsers(domain, resellerId) {
    const users = await this.request('get', {
      url: "/b2bapi/admin/reseller/" + resellerId + "/user",
      domain
    }
    );
    return users ? users : null
  }

  async createUser(domain, resellerId, user) {
    const res = await this.request("post", {
      domain,
      url: "/b2bapi/admin/reseller/" + resellerId + "/user",
      body: user,
      successMsg: "User " + user.firstname + user.lastname + " created",
    });
    return res;
  }

  async updateUser(domain, resellerId, user) {
    const options = {
      domain,
      url: "/b2bapi/admin/reseller/" + resellerId + "/user/" + user.id,
      body: user,
      successMsg: "User " + user.firstname + " " + user.lastname + " updated",
    };
    return await this.request("put", options);
  }

  async getLoginAsInformation(domain, resellerId, userId) {
    return await this.request("get", {
      domain,
      url: "/b2bapi/admin/reseller/" + resellerId + "/user/" + userId + "/loginAs",
    });
  }

  async loginAs(domain, body) {
    return await this.request("post", {
      domain,
      url: "/b2bapi/loginAs",
      body
    }, {
      credentials: "include",
    });
  }

  /* ---------  RESELLER EXCHANG RATE -------------  */

  async getExRates(domain, resellerId) {
    const options = {
      domain: domain,
      url: "/b2bapi/admin/reseller/" + resellerId + "/exchangeRate",
    };
    return await this.request("get", options);
  }

  async loadExRate(domain, resellerId, exRateId) {
    const exRate = await this.request('get', {
      domain,
      url: "/b2bapi/admin/reseller/" + resellerId + "/exchangeRate/" + exRateId,
    }
    );
    return exRate ? exRate : null
  }

  async upsertExRate(domain, resellerId, exrate) {
    const options = {
      domain,
      url: "/b2bapi/admin/reseller/" + resellerId + "/exchangeRate/" + exrate.id,
      body: exrate,
      successMsg: "Exchange rate " + exrate.name + " (" + exrate.rate + ") upserted",
    };
    return await this.request("put", options);
  }

  async deleteExRate(domain, resellerId, exrate) {
    const options = {
      domain,
      url: "/b2bapi/admin/reseller/" + resellerId + "/exchangeRate/" + exrate.id,
      successMsg: "Exchange rate " + exrate.name + " (" + exrate.rate + ") deleted",
    };
    return await this.request("delete", options);
  }

  /* ---------  RESELLER CASHDESK -------------  */

  async getCashdesks(domain, resellerId) {
    const options = {
      domain: domain,
      url: "/b2bapi/admin/reseller/" + resellerId + "/cashdesk",
    };
    return await this.request("get", options);
  }

  async loadCashdesk(domain, resellerId, cashdeskId) {
    const cashdesk = await this.request('get', {
      domain,
      url: "/b2bapi/admin/reseller/" + resellerId + "/cashdesk/" + cashdeskId,
    }
    );
    return cashdesk ? cashdesk : null
  }

  async upsertCashdesk(domain, resellerId, cashdesk) {
    const options = {
      domain,
      url: "/b2bapi/admin/reseller/" + resellerId + "/cashdesk/" + cashdesk.id,
      body: cashdesk,
      successMsg: "Cashdesk " + cashdesk.name + " (" + cashdesk.id + ") upserted",
    };
    return await this.request("put", options);
  }

  async deleteCashdesk(domain, resellerId, cashdesk) {
    const options = {
      domain,
      url: "/b2bapi/admin/reseller/" + resellerId + "/cashdesk/" + cashdesk.id,
      successMsg: "Cashdesk " + cashdesk.name + " (" + cashdesk.id + ") deleted",
    };
    return await this.request("delete", options);
  }

  /* ---------  RESELLER CASHDESK PRINTER-------------  */

  async getCashdeskPrinters(domain, resellerId, cashdesksId) {
    const options = {
      domain: domain,
      url: "/b2bapi/admin/reseller/" + resellerId + "/cashdesk/" + cashdesksId + "/printer",
    };
    return await this.request("get", options);
  }

  async loadCashdeskPrinter(domain, resellerId, cashdeskId, printerId) {
    const printer = await this.request('get', {
      domain,
      url: "/b2bapi/admin/reseller/" + resellerId + "/cashdesk/" + cashdeskId + "/printer/" + printerId,
    }
    );
    return printer ? printer : null
  }

  async upsertCashdeskPrinter(domain, resellerId, cashdeskId, printer) {
    const options = {
      domain,
      url: "/b2bapi/admin/reseller/" + resellerId + "/cashdesk/" + cashdeskId + "/printer/" + printer.id,
      body: printer,
      successMsg: "Cashdesk printer " + printer.name + " (" + printer.id + ") upserted",
    };
    return await this.request("put", options);
  }

  async deleteCashdeskPrinter(domain, resellerId, cashdeskId, printer) {
    const options = {
      domain,
      url: "/b2bapi/admin/reseller/" + resellerId + "/cashdesk/" + cashdeskId + "/printer/" + printer.id,
      successMsg: "Cashdesk printer " + printer.name + " (" + printer.id + ") deleted",
    };
    return await this.request("delete", options);
  }
  /* ---------  RESELLER CONTRACT -------------  */

  async getContracts(domain, resellerId) {
    const contracts = await this.request('get', {
      url: "/b2bapi/admin/reseller/" + resellerId + "/contract",
      domain
    }
    );
    return contracts ? contracts : null
  }

  async loadContract(domain, resellerId, contractId) {
    const contract = await this.request('get', {
      domain,
      url: "/b2bapi/admin/reseller/" + resellerId + "/contract/" + contractId,
    }
    );
    return contract ? contract : null
  }

  async createContact(domain, resellerId, contract) {
    return await this.request("post", {
      domain,
      url: "/b2bapi/admin/reseller/" + resellerId + "/contract",
      body: contract,
      successMsg: "Contract for reseller/domain " + resellerId + "/" + contract.srcDomain + " created",
    });
  }

  async updateContract(domain, resellerId, contract) {
    const options = {
      domain,
      url: "/b2bapi/admin/reseller/" + resellerId + "/contract/" + contract.id,
      body: contract,
      successMsg: "Contract " + contract.id + " updated",
    };
    return await this.request("put", options);;
  }

  async deleteContract(domain, resellerId, contract) {
    const options = {
      domain,
      url: "/b2bapi/admin/reseller/" + resellerId + "/contract/" + contract.id,
      successMsg: "Contract " + contract.id + " deleted",
    };
    return await this.request("delete", options);
  }
}

export default B2BResellerApiClient
