import BaseRestClient from '@/assets/js/api/BaseRestClient'

class CoreAdminApiClient extends BaseRestClient {
    constructor(baseURL, errorCallback, successCallback) {
        super(baseURL, "CoreAdminApiClient", errorCallback, successCallback);
    }

    async runCatalogWorker(params = {}) {
        return this.request("post", {
            url: "admin/runWorker",
            successMsg: "Triggered catalog worker successfully",
            ...params
        });
    }

}

export default CoreAdminApiClient