/** Plugin for admin UI utility methods */

//JS libraries
import cc from 'currency-codes';
import currencies from 'currency-codes/data'
import equal from 'fast-deep-equal';
import copy from 'fast-copy';
import { countries } from 'countries-list';
import DOMPurify from "dompurify";
import goTo from 'vuetify/lib/services/goto'

//Dialogs
import SimpleConfirmDialog from '../components/SimpleConfirmDialog'
import SimplePromptDialog from "../components/SimplePromptDialog"

//List of valid codes for locales
/*
TODO: Is disabled for now, enable if locales should be validated for correct country and language
const iso_639_1 = ['ab', 'aa', 'af', 'ak', 'sq', 'am', 'ar', 'an', 'hy', 'as', 'av', 'ae', 'ay', 'az', 'bm', 'ba', 'eu', 'be', 'bn', 'bh', 'bi', 'bs', 'br', 'bg', 'my', 'ca', 'km', 'ch', 'ce', 'ny', 'zh', 'cu', 'cv', 'kw', 'co', 'cr', 'hr', 'cs', 'da', 'dv', 'nl', 'dz', 'en', 'eo', 'et', 'ee', 'fo', 'fj', 'fi', 'fr', 'ff', 'gd', 'gl', 'lg', 'ka', 'de', 'ki', 'el', 'kl', 'gn', 'gu', 'ht', 'ha', 'he', 'hz', 'hi', 'ho', 'hu', 'is', 'io', 'ig', 'id', 'ia', 'ie', 'iu', 'ik', 'ga', 'it', 'ja', 'jv', 'kn', 'kr', 'ks', 'kk', 'rw', 'kv', 'kg', 'ko', 'kj', 'ku', 'ky', 'lo', 'la', 'lv', 'lb', 'li', 'ln', 'lt', 'lu', 'mk', 'mg', 'ms', 'ml', 'mt', 'gv', 'mi', 'mr', 'mh', 'ro', 'mn', 'na', 'nv', 'nd', 'ng', 'ne', 'se', 'no', 'nb', 'nn', 'ii', 'oc', 'oj', 'or', 'om', 'os', 'pi', 'pa', 'ps', 'fa', 'pl', 'pt', 'qu', 'rm', 'rn', 'ru', 'sm', 'sg', 'sa', 'sc', 'sr', 'sn', 'sd', 'si', 'sk', 'sl', 'so', 'st', 'nr', 'es', 'su', 'sw', 'ss', 'sv', 'tl', 'ty', 'tg', 'ta', 'tt', 'te', 'th', 'bo', 'ti', 'to', 'ts', 'tn', 'tr', 'tk', 'tw', 'ug', 'uk', 'ur', 'uz', 've', 'vi', 'vo', 'wa', 'cy', 'fy', 'wo', 'xh', 'yi', 'yo', 'za', 'zu'];
const iso_3166_alpha_2 = ['AD', 'AE', 'AF', 'AG', 'AI', 'AL', 'AM', 'AO', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AW', 'AX', 'AZ', 'BA', 'BB', 'BD', 'BE', 'BF', 'BG', 'BH', 'BI', 'BJ', 'BL', 'BM', 'BN', 'BO', 'BQ', 'BR', 'BS', 'BT', 'BV', 'BW', 'BY', 'BZ', 'CA', 'CC', 'CD', 'CF', 'CG', 'CH', 'CI', 'CK', 'CL', 'CM', 'CN', 'CO', 'CR', 'CU', 'CV', 'CW', 'CX', 'CY', 'CZ', 'DE', 'DJ', 'DK', 'DM', 'DO', 'DZ', 'EC', 'EE', 'EG', 'EH', 'ER', 'ES', 'ET', 'FI', 'FJ', 'FK', 'FM', 'FO', 'FR', 'GA', 'GB', 'GD', 'GE', 'GF', 'GG', 'GH', 'GI', 'GL', 'GM', 'GN', 'GP', 'GQ', 'GR', 'GS', 'GT', 'GU', 'GW', 'GY', 'HK', 'HM', 'HN', 'HR', 'HT', 'HU', 'ID', 'IE', 'IL', 'IM', 'IN', 'IO', 'IQ', 'IR', 'IS', 'IT', 'JE', 'JM', 'JO', 'JP', 'KE', 'KG', 'KH', 'KI', 'KM', 'KN', 'KP', 'KR', 'KW', 'KY', 'KZ', 'LA', 'LB', 'LC', 'LI', 'LK', 'LR', 'LS', 'LT', 'LU', 'LV', 'LY', 'MA', 'MC', 'MD', 'ME', 'MF', 'MG', 'MH', 'MK', 'ML', 'MM', 'MN', 'MO', 'MP', 'MQ', 'MR', 'MS', 'MT', 'MU', 'MV', 'MW', 'MX', 'MY', 'MZ', 'NA', 'NC', 'NE', 'NF', 'NG', 'NI', 'NL', 'NO', 'NP', 'NR', 'NU', 'NZ', 'OM', 'PA', 'PE', 'PF', 'PG', 'PH', 'PK', 'PL', 'PM', 'PN', 'PR', 'PS', 'PT', 'PW', 'PY', 'QA', 'RE', 'RO', 'RS', 'RU', 'RW', 'SA', 'SB', 'SC', 'SD', 'SE', 'SG', 'SH', 'SI', 'SJ', 'SK', 'SL', 'SM', 'SN', 'SO', 'SR', 'SS', 'ST', 'SV', 'SX', 'SY', 'SZ', 'TC', 'TD', 'TF', 'TG', 'TH', 'TJ', 'TK', 'TL', 'TM', 'TN', 'TO', 'TR', 'TT', 'TV', 'TW', 'TZ', 'UA', 'UG', 'UM', 'US', 'UY', 'UZ', 'VA', 'VC', 'VE', 'VG', 'VI', 'VN', 'VU', 'WF', 'WS', 'YE', 'YT', 'ZA', 'ZM', 'ZW'];
*/
export default {

    install(Vue) {

        /** Deep clones an object using the fast-copy library 
         * https://www.npmjs.com/package/fast-copy
        */
        Vue.prototype.$cloneObject = function (o) {
            try {
                if (o === undefined) return undefined;
                return copy(o);
            } catch (e) {
                console.error(e);
                return undefined;
            }
        }

        /** Deep compares two objects using the fast-deep-equal library
         * https://www.npmjs.com/package/fast-deep-equal
         */
        Vue.prototype.$isEqual = function (o1, o2) {
            return equal(o1, o2);
        },

            /** Checks if the object is a plain js object e.g. {a: 1, b: "test"} */
            Vue.prototype.$isPlainObject = function (o) {
                return (o === null || o === undefined || Array.isArray(o) || typeof o === 'function' || typeof o === 'string' || o.constructor === Date) ?
                    false
                    : (typeof o === 'object');
            },

            /** Traverses an nested object through the given property path (seperated by dots) and returns the value 
             * Returns undefined if no value is found
             * 
            */
            Vue.prototype.$getObjectValueByPath = function (obj, path) {
                return path.split('.').reduce((prev, curr) => {
                    if (curr.includes("+")) {
                        curr = curr.replace("+", ".");
                    }
                    return prev?.[curr];
                }, obj);
            },

            /** Returns the type of the given object */
            Vue.prototype.$getType = function (o) {
                if (o === undefined) return undefined;
                //return the type of an object
                if (Array.isArray(o)) {
                    return 'array';
                }
                if (this.$isPlainObject(o)) {
                    return 'object';
                }
                return typeof o;
            },

            /* ######## PRICE UTIL ######## */

            /** Return existing currencies */
            Vue.prototype.$getCurrencies = function () {
                return currencies;
            }

        /** Formats the given price value to a readable string in the given currency e.g 10000 + EUR = 10,00 EUR
        * The value has to be in the smallest unit of the given currency
        */
        Vue.prototype.$parseFractionUnitToString = function (price, currency /* e.g. USD, EUR */) {
            if (!price) price = 0;
            if (!currency) return price;
            //check if its a valid currency. If not return the plain price
            const currencyObj = cc.code(currency);
            if (!currencyObj) return price;
            const decimals = currencyObj.digits ?? 0;
            const localePrice = Number(price) / Math.pow(10, decimals);
            let formattedPrice;
            try {
                formattedPrice = new Intl.NumberFormat(navigator.languages, { style: 'currency', currency }).format(localePrice);
            } catch (e) {
                console.error(e);
                formattedPrice = new Intl.NumberFormat(navigator.languages, { style: 'currency', currency: "EUR" }).format(localePrice);
            }
            return formattedPrice;
        }

        /** Parses given price to the fractional monetary unit of given currency e.g. 10 + EUR => 10000 (cents) */
        Vue.prototype.$parsePriceToFractionUnit = function (value, currency /* e.g. USD, EUR */) {
            if (!currency) return value;
            const decimals = cc.code(currency)?.digits;
            return Number(value) * Math.pow(10, decimals);
        }

        /** Multiplies the amount in fractional monetary unit with the currency decimals and returns it as number e.g. 10500 + EUR => 10,5 */
        Vue.prototype.$parseFractionUnitToPrice = function (price, currency /* e.g. USD, EUR */) {
            if (!currency) return price;
            const decimals = cc.code(currency)?.digits;
            //remove trailing zeros
            return parseFloat(Number(price) / Math.pow(10, decimals));
        }

        /* ###### COUNTRY AND LOCALE UTIL ########## */

        /** Checks if the given locale has a valid format e.g. en-GB, de-DE */
        Vue.prototype.$isValidLocale = function (locale) {
            if (!locale || typeof locale !== "string") return false;
            //check correct format
            const localeRegEx = new RegExp("^[a-z]{2}-[A-Z]{2}$");
            if (!localeRegEx.test(locale)) return false;

            /* TODO: Is disabled for now, enable if locales should be validated for correct country and language
            //check if the language and optional country code are valid
            const parts = locale.split("-");
            const languageCode = parts[0];
            const countryCode = parts?.[1];
            if(!iso_639_1.some(lc => lc === languageCode)) return false;
            if(countryCode && !iso_3166_alpha_2.some(cc => cc === countryCode)) return false;
            */
            return true;
        }

        /** Returns a list of all existing countries with  ISO 3166 Alpha 2 code, localized name and more information */
        Vue.prototype.$getCountries = function () {
            const countryNames = new Intl.DisplayNames(["en-GB"], { type: 'region' });
            return Object.keys(countries).map(code => {
                const country = countries[code];
                //Get the country name from the native internationalization API
                //if the name does not exist, use the given name from the country object
                let name = countryNames.of(code);
                if (name === code) name = country.name;
                return {
                    code,
                    name,
                    ...country
                };
            }).sort((c1, c2) => {
                return c1.name.localeCompare(c2.name);
            });

        };

        /* ######## DATE UTIL ######## */

        /** Returns a localized readable date-time string */
        Vue.prototype.$getLocalizedDate = function (dateString, options, locales) {
            try {
                if (!locales || locales?.length === 0) {
                    const userLocale = navigator.languages && navigator.languages.length
                        ? navigator.languages[0]
                        : navigator.language;
                    if (userLocale) locales = [userLocale]
                    else locales = ["en-GB"];
                }
                if (!options) {
                    options = {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                    }
                }
                const date = new Date(dateString);
                return new Intl.DateTimeFormat(locales, options).format(date);
            } catch (e) {
                console.error(e);
                return dateString;
            }
        }

        /** Returns the given date as ISO-8601 formatted string */
        Vue.prototype.$formatToISO = function (date /* JS Date */, includeTime = true, includeTimezone = false) {
            const tzo = -date.getTimezoneOffset();
            const dif = tzo >= 0 ? '+' : '-';
            const pad = (num) => {
                return (num < 10 ? '0' : '') + num;
            };

            let iso = date.getFullYear() +
                '-' + pad(date.getMonth() + 1) +
                '-' + pad(date.getDate());

            if (includeTime) {
                iso += 'T' + pad(date.getHours()) +
                    ':' + pad(date.getMinutes()) +
                    ':' + pad(date.getSeconds());
            }

            if (includeTimezone) {
                iso += dif +
                    pad(Math.floor(Math.abs(tzo) / 60)) +
                    ':' + pad(Math.abs(tzo) % 60);
            }

            return iso;
        }

        //Checks if two dates are on the same day
        Vue.prototype.$isSameDay = function (first /* Date */, second /* Date */) {
            return first.getFullYear() === second.getFullYear() &&
                first.getMonth() === second.getMonth() &&
                first.getDate() === second.getDate();
        }

        //Returns the difference between two dates in full days
        Vue.prototype.$getDayDiff = function (first /* Date */, second /* Date */) {
            // Discard the time and time-zone information.
            const utc1 = Date.UTC(first.getFullYear(), first.getMonth(), first.getDate());
            const utc2 = Date.UTC(second.getFullYear(), second.getMonth(), second.getDate());
            return Math.floor((utc2 - utc1) / (1000 * 3600 * 24));
        }

        /* ######## URL UTIL ######## */

        /** Parse a text to base64 and encode the string url-safe */
        Vue.prototype.$urlEncode = function (plainText) {
            const buf = Buffer.from(plainText);
            return encodeURIComponent(buf.toString('base64'));
        }

        /** Decodes a previously encoded base64 string and parses it to the original text */
        Vue.prototype.$urlDecode = function (base64Text) {
            const decoded = decodeURIComponent(base64Text);
            const buf = Buffer.from(decoded, 'base64');
            return buf.toString();
        }


        /* ######## FORM UTIL ######## */

        /**
         * Validates the given vuetify form. If the form is not valid 
         * it scrolls to and focuses the first invalid input
         * @param {*} vform The form which should be validated
         * @param {Boolean} forceFocus Tries to focus an invalid input field even if the form is valid
         * @returns true or false, depending if the form is valid or not
         */
        Vue.prototype.$validateVForm = function (vform, forceFocus) {
            const isValid = vform.validate();
            if (!isValid || forceFocus) {
                //if at least on input is invalid, focus the first invalid input
                const input = vform?.inputs.find((input) => !input.valid || input.hasError);
                if (input) {
                    goTo(input.$el ?? input);
                    //get the correct input element inside the vuetify inputs
                    input?.$refs?.input?.focus() ||
                        input?.$el.querySelector("input:not([type=hidden])")?.focus();
                }
            }
            return isValid;
        }


        /* ######## DIALOG HELPERS ######## */

        /**
         * Opens a confirmation dialog with given title, subtext and properties
         * @param {*} title The title of the dialog
         * @param {*} text The subtext of the dialog
         * @param {*} props Property object for the dialog e.g. { width: 400 }
         * @returns asynchronous true (confirmed) or false (canceled)
         */
        Vue.prototype.$confirm = async function (title, text, props) {
            //create a new Confirmation Dialog component instance and open it
            const ConfirmDialogClass = Vue.extend(SimpleConfirmDialog);
            const confirmDialog = new ConfirmDialogClass({
                propsData: {
                    title,
                    text,
                    ...props
                }
            }).$mount();
            document.getElementById("app").appendChild(confirmDialog.$el);
            //open the dialog and wait for input
            const confirm = await confirmDialog.open();
            // destroy the vue listeners, etc
            confirmDialog.$destroy();
            // remove the element from the DOM
            confirmDialog.$el.parentNode.removeChild(confirmDialog.$el);
            return confirm;
        }

        /**
         * Opens a prompt dialog with given title and properties
         * @param {*} title The title of the dialog
         * @param {*} props Property object for the dialog e.g. { width: 400 }
         * @returns The input asynchronously
         */
        Vue.prototype.$prompt = async function (title, props) {
            //create a new Prompt Dialog component instance and open it
            const SimplePromptDialogClass = Vue.extend(SimplePromptDialog)
            const promptDialog = new SimplePromptDialogClass({
                propsData: {
                    title,
                    ...props
                },
            }).$mount();
            document.getElementById('app').appendChild(promptDialog.$el);
            //open the dialog and wait for input
            const value = await promptDialog.open();
            // destroy the vue listeners, etc
            promptDialog.$destroy();
            // remove the element from the DOM
            promptDialog.$el.parentNode.removeChild(promptDialog.$el);
            return value;
        }

        /* ######## Security ######## */

        /**
         * DOMPurify sanitizes HTML and prevents XSS attacks. 
        */
        Vue.prototype.$sanitize = function (html, options) {
            return DOMPurify.sanitize(html, options);
        }
    }
}