
const allowedHeaderTypes = ["boolean", "date", "text", "long", "integer", "short", "byte", "double", "float", "half_float", "scaled_float", "unsigned_long", "_source"];
const languageNames = new Intl.DisplayNames([window.navigator.language], { type: 'language' }); //all countries grouped by language

function parseCapabilities(fieldCapabilities, { tableHeaders = [], filters = [], sortableFields = {} } = {}) {
    const fields = fieldCapabilities.fields;
    let languageHeaders = {};

    if (fields) {
        Object.keys(fields).forEach(key => {
            const field = fields[key];
            const fieldType = Object.keys(field)[0];

            if (sortableFields[field]) return;
            if (key.includes(".")) {
                //parse object headers
                const parts = key.split(".");
                const label = parts[0];
                const lang = parts[1];
                //check if the header value is language dependent e.g. a label
                if (lang.length === 2 && lang != "id" && languageNames.of(lang) != lang) {
                    languageHeaders[label] = Object.assign({}, languageHeaders[label], {
                        [lang]: {
                            searchable: field[fieldType].searchable
                        },
                    });

                    return;
                }
            }

            if (!key.includes("keyword") && allowedHeaderTypes.includes(fieldType)) {
                const properties = field[fieldType];
                const type = properties.type;
                //HEADERS
                const value = type === "_source" ? key : "_source." + key
                tableHeaders.push({
                    text: key,
                    value,
                    sortable: type !== "_source",
                    type
                });

                //FILTERS
                if (properties.searchable) {
                    let filter = {
                        text: key,
                        property: key,
                        type,
                    };
                    if (type === "date") {
                        filter.parseToUTC = true;
                    }

                    filters.push(filter);
                }

                //SORTABLE
                sortableFields[key] = { type }
            }
        });

        //add language dependent fields to the headers and filters
        Object.keys(languageHeaders).forEach(key => {
            const header = languageHeaders[key];
            const userLanguage = window.navigator.language.split("-")[0];
            let lang = Object.keys(header)[0];

            if (header[userLanguage]) {
                //use the label in the users language, if available
                lang = userLanguage;
            }

            tableHeaders.push({
                text: key,
                value: "_source." + key + "." + lang,
                sortable: false
            });

            /* 
            //TODO: Check if language dependent filters are useful.
            //--> May lead to problems with different browser languages when sharing a deep link
            if(header[lang].searchable){
                filters.push({text: key, property: key + "." + lang, type: "text" });
            }
            */
        });
    }

    return {
        headers: tableHeaders,
        filters,
        sortableFields
    };
}

exports.allowedHeaderTypes = allowedHeaderTypes;
exports.languageNames = languageNames;
exports.parseCapabilities = parseCapabilities;

