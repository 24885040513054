import BaseRestClient from '@/assets/js/api/BaseRestClient'
import { parseCapabilities } from '@/assets/js/utilities/es/capabilities.js'

class CoreLoggingApiClient extends BaseRestClient {
    constructor(baseURL, errorCallback, successCallback) {
        super(baseURL, "CoreLoggingApiClient", errorCallback, successCallback);
    }

    async searchLogs(domain, body, signal) {
        const res = await this.request("post", {
            url: "/log/search",
            domain,
            body,
            signal,
            successMsg: null
        });

        if (res?.aborted) return res;
        const searchResult = await res?.json?.();
        let result = {
            items: [],
            total: 0
        }
        if (searchResult?.hits) {
            result.total = searchResult.hits.total.value;
            result.items = searchResult.hits.hits;
        }

        if (searchResult?.aggregations) {
            result.aggregations = searchResult.aggregations;
        }
        return result;
    }

    async getCapabilities(domain) {
        try {
            const res = await this.request("get", {
                url: "/log/capabilities",
                domain,
                successMsg: null,
                returnErrors: true
            });

            const fieldCapabilities = res ?? {};
            return parseCapabilities(fieldCapabilities);
        } catch (e) {
            console.error(e)
            if (typeof this.errorCallback === 'function') this.errorCallback(e);
            else throw e;
        }
    }

}

export default CoreLoggingApiClient;