

const passwordFormat = "(?=.*[A-Za-z])(?=.*\\d).{6,}";
const passwordRegex = new RegExp(passwordFormat);
const emailRegex = new RegExp("^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,5})+$");
export default {
    computed: {
        ruleSet() {
            return {
                required: (val) => {
                    if (Array.isArray(val)) {
                        return val.length !== 0 || "Value is required for selection";
                    }

                    return !!val || "Value is required";
                },
                domain: (v) => (!!v && !!v.id) || "Value is required",
                password: (val) => {
                    if (!val) return true;
                    return (
                        passwordRegex.test(val) ||
                        "Password must be in valid format " + passwordFormat
                    );
                },
                email: (val) => {
                    if (!val) return true;
                    return emailRegex.test(val) || "Email address must have a valid format";
                },
                afterFromDate: (fromDate) => {
                    return (date) => {
                        if (!fromDate) return true;
                        const isAfter =
                            !!date && this.$getDayDiff(new Date(fromDate), new Date(date)) >= 0;
                        return (
                            isAfter ||
                            "Date must be after or equal to " +
                            this.$getLocalizedDate(fromDate, {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                            })
                        );
                    }
                },
                beforeUntilDate: (untilDate) => {
                    return (date) => {
                        if (!untilDate) return true;
                        const isBefore =
                            !!date &&
                            this.$getDayDiff(new Date(date), new Date(untilDate)) >= 0;
                        return (
                            isBefore ||
                            "Date must be before or equal to " +
                            this.$getLocalizedDate(untilDate, {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                            })
                        );
                    }
                },
                minLength: (minLength) => {
                    return (value) => {
                        if (value === null || value === undefined) return true;
                        return value.length >= minLength || "Value have be at least " + minLength + " character" + (minLength > 1 ? "s" : "")
                    }
                },
                maxLength: (maxLength) => {
                    return (value) => {
                        if (value === null || value === undefined) return true;
                        return value.length <= maxLength || "Value must not be longer than " + maxLength + " character" + (maxLength > 1 ? "s" : "");
                    }
                },
            };
        }
    }
}