import BaseRestClient from '@/assets/js/api/BaseRestClient'
class CoreDomainApiClient extends BaseRestClient {
    constructor(baseURL, errorCallback, successCallback) {
        super(baseURL, "CoreDomainApiClient", errorCallback, successCallback);
    }

    async getDomains() {
        return this.request("get", {
            url: "/domain"
        });
    }

    async upsertDomain(parentId, domainId, body, params = {}) {
        return this.request("put", {
            url: "domain/" + parentId + "/" + domainId,
            body,
            ...params
        })
    }
}

export default CoreDomainApiClient