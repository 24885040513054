import Vue from "vue";
import App from "./App.vue";
import UUID from "vue-uuid";
import store from "./store";
import router from "./router";
import extensions from "./extensions";

//PLUGINS
import util from "./plugins/util";
import vuetify from "./plugins/vuetify";
import constants from "./plugins/constants";
import keycloak from "./plugins/keycloak";
import componentLoader from "./plugins/component-loader";

import updateToken from "./assets/js/utilities/keycloak/update-token";
import "./registerServiceWorker";

Vue.config.productionTip = false;
Vue.use(keycloak);

Vue.$keycloak
  .init({ onLoad: "login-required" })
  .then(authenticated => {
    if (!authenticated) {
      throw Error("Login failed: User could not be authenticated!");
    }

    Vue.use(UUID);
    Vue.use(util);
    Vue.use(constants);
    Vue.use(componentLoader);

    const extensionsRoutes = extensions.routes ?? [];
    if (extensionsRoutes.length > 0) {
      extensionsRoutes.forEach(item => {
        const route = item.route;
        const parentRoute = item.parentRoute;
        if (parentRoute) {
          router.addRoute(parentRoute, route);
        } else {
          router.addRoute(route);
        }
      });
    }

    Vue.prototype.$extensions = extensions.extensions;
    //eslint-disable-next-line
    Vue.prototype.$configuration = BASE_CONFIGURATION;

    new Vue({
      vuetify,
      store,
      router,
      render: h => h(App)
    }).$mount("#app");

    //Update the keycloak token if the user focuses the browser tab
    window.onfocus = () => updateToken();
  })
  .catch(error => {
    alert(
      "Something went wrong while authenticating! Please look in the console"
    );
    console.error(error);
  });
