export default {
    data() {
        return {
            refreshing: false,
            registration: null,
            updateExists: false,
        }
    },
    created() {
        //List to the custom event emitted by registerServiceWorker.js
        document.addEventListener('swUpdated', this.updateAvailable, { once: true })

        //If the controlling service worker has changed, reload the page
        if(navigator.serviceWorker){
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                // Prevent multiple refreshes
                if (this.refreshing) return
                this.refreshing = true;
                // Here the actual reload of the page occurs
                window.location.reload();
            })
        }
       
    },
    methods: {
        updateAvailable(event) {
            //There is an update available, so set the updateExists flag to true
            this.registration = event.detail
            this.updateExists = true
        },

        refreshApp() {
            this.updateExists = false
            // Make sure we only send a 'skip waiting' message if the service worker is waiting
            if (!this.registration || !this.registration.waiting) return
            // Send message to service worker to skip the waiting and activate the new worker
            this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
        }
    }
}