import BaseRestClient from '@/assets/js/api/BaseRestClient'

class CoreServiceProviderApiClient extends BaseRestClient {
    constructor(baseURL, errorCallback, successCallback) {
        super(baseURL, "CoreServiceProviderApiClient", errorCallback, successCallback);
    }

    getServiceProviders(domain, params = {}) {
        return this.request("get", {
            url: "/serviceproviders",
            domain,
            ...params,
        });
    }

    getServiceProvider(domain, providerId, params = {}) {
        return this.request("get", {
            url: "/serviceproviders/" + providerId,
            domain,
            ...params,
        });
    }

}

export default CoreServiceProviderApiClient;