
import Vue from 'vue'
import Keycloak from 'keycloak-js';

//See: https://davidtruxall.com/secure-a-vue-js-app-with-keycloak/
//See: https://medium.com/js-dojo/authentication-made-easy-in-vue-js-with-keycloak-c03c7fff67bb



/*
    --- Keycloak client setup ----

    * Create new client "admin-ui" in "system" realm
    * Turn "Client Authentication" off
    * Add domain of admin ui to "Web Origins"
    * To activate the "Forget Password?" link, check "Realm Settings"
     
    
    --- TODOS ----

    1. Implement refresh token functionality
    2. Check if authorization is also needed in vue router
    
*/

//eslint-disable-next-line
const config = BASE_CONFIGURATION;
const url = config.keycloakDomain + "/auth";
const realm = config.keycloakRealm;
const clientId = config.keycloakClientId

const initOptions = {
    url, realm, clientId
};
const keycloak = new Keycloak(initOptions);
const plugin = {
    install(Vue) {
        Vue.prototype.$keycloak = keycloak;
    }
}

//Needed so the init function in main.js works
plugin.install = Vue => {
    Vue.$keycloak = keycloak
    Object.defineProperties(Vue.prototype, {
        $keycloak: {
            get() {
                return keycloak
            }
        }
    })
}

Vue.use(plugin);

export default plugin;