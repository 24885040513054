import BaseRestClient from '@/assets/js/api/BaseRestClient'

class CoreOrderApiClient extends BaseRestClient {
    constructor(baseURL, errorCallback, successCallback) {
        super(baseURL, "CoreOrderApiClient", errorCallback, successCallback);
    }

    async searchOrders(domain, body, signal, params = {}) {
        return await this.request("post", {
            domain,
            url: "/orders",
            body,
            signal,
            successMsg: null,
            ...params
        });
    }

    async getOrder(domain, orderId) {
        return await this.request("get", {
            domain,
            url: "/order/" + orderId,
        })
    }

    async getHistory(domain, orderId) {
        return await this.request("get", {
            domain,
            url: "/order/" + orderId + "/history"
        });
    }

    async createHistory(domain, orderId, comment) {
        return await this.request("post", {
            domain,
            url: "/order/" + orderId + "/history",
            body: {
                comment
            },
            successMsg: "Created new comment in order history"
        });
    }

    async getProductions(domain, query = "") {
        return this.request("get", {
            domain,
            url: "/production",
            query
        })
    }

    async retryProducing(domain, orderId, productionIds = [], successMsg) {
        return this.request("post", {
            domain,
            url: "/order/" + orderId + "/retryProducing",
            body: {
                productionIds
            },
            successMsg: successMsg ?? "Retry producing productions " + productionIds.join(",")
        })
    }

    async getCancellations(domain, query = "", body, params = {}) {
        return this.request("post", {
            domain,
            url: "/order/cancellation",
            query,
            body,
            successMsg: null,
            ...params
        })
    }

    async getPayments(domain, orderId, provider, params = {}) {
        let queryArray = [];
        if (orderId) queryArray.push("orderId=" + orderId);
        if (provider) queryArray.push("provider=" + provider);
        const query = queryArray.join("&");
        return this.request("get", {
            domain,
            url: "/payment",
            query,
            successMsg: null,
            ...params
        })
    }

    async sendOrderConfirmationMail(domain, orderId, body, params = {}) {
        return this.request("post", {
            domain,
            url: "/order/" + orderId + "/sendOrderConfirmation",
            body,
            successMsg: "Sent order confirmation mail for order " + orderId,
            ...params
        })
    }
}

export default CoreOrderApiClient;