import BaseRestClient from '@/assets/js/api/BaseRestClient'
class CoreContractApiClient extends BaseRestClient {
    constructor(baseURL, errorCallback, successCallback) {
        super(baseURL, "CoreContractApiClient", errorCallback, successCallback);
    }

    async getContractTerms(domain, body, params = {}) {
        return this.request("post", {
            domain,
            url: "/contractTerms",
            body,
            successMsg: null,
            ...params,
        })
    }

    async upsertContractTerms(domain, body, params = {}) {
        return this.request("put", {
            domain,
            url: "/contractTerms",
            body,
            successMsg: "Contract Terms successfully updated",
            returnErrors: true,
            ...params,
        })
    }

    async getContractTriggers(domain, body, params = {}) {
        return this.request("post", {
            domain,
            url: "/contractTriggers",
            body,
            successMsg: null,
            ...params,
        })
    }

    async createContractTrigger(domain, body, params = {}) {
        return this.request("post", {
            domain,
            url: "/contractTrigger",
            body,
            successMsg: "Contract trigger created",
            ...params,
        })
    }

    async updateContractTrigger(domain, body, params = {}) {
        return this.request("put", {
            domain,
            url: "/contractTrigger",
            body,
            successMsg: "Contract trigger updated",
            ...params,
        })
    }

    async deleteContractTrigger(domain, contractTriggerKey, params = {}) {
        return await this.request("delete", {
            domain,
            url: "/contractTrigger",
            body: { contractTriggerKey },
            successMsg: "Contract trigger deleted",
            ...params
        })
    }


}

export default CoreContractApiClient