import BaseRestClient from '@/assets/js/api/BaseRestClient'

class CoreUserApiClient extends BaseRestClient {
    constructor(baseURL, errorCallback, successCallback) {
        super(baseURL, "CoreUserApiClient", errorCallback, successCallback);
    }

    async searchUsers(domain, body, signal, params = {}) {
        return await this.request("post", {
            url: domain + "/users",
            body,
            signal,
            successMsg: null,
            ...params
        });
    }

    async getUser(domain, userId, params = {}) {
        return await this.request("get", {
            domain,
            url: "/user/" + userId,
            ...params
        })
    }

    async createUser(domain, body, params = {}) {
        const username = body?.user?.username;
        return await this.request("post", {
            domain,
            url: "/user",
            body,
            successMsg: "User " + username + " created",
            ...params
        })
    }

    async updateUser(domain, body, params = {}) {
        const userId = body?.id;
        const username = body?.username;
        return await this.request("put", {
            domain,
            url: "/user/" + userId,
            body,
            successMsg: "User " + username + " updated",
            ...params
        });
    }

    async deleteUser(domain, userId, params = {}) {
        return await this.request("delete", {
            domain,
            url: "/user/" + userId,
            successMsg: "User " + userId + " deleted",
            ...params
        });
    }

    async getRoles(domain, params = {}) {
        return await this.request("get", {
            domain,
            url: "/role",
            ...params
        });
    }

    async getUserRoles(domain, userId, params = {}) {
        return await this.request("get", {
            domain,
            url: "/user/" + userId + "/roles",
            ...params
        });
    }

    async addUserRoles(domain, userId, roles = [], params = {}) {
        return await this.request("patch", {
            domain,
            url: "/user/" + userId + "/roles",
            body: {
                roles
            },
            ...params
        });
    }

    async deleteUserRoles(domain, userId, roles = [], params = {}) {
        return await this.request("delete", {
            domain,
            url: "/user/" + userId + "/roles",
            body: {
                roles
            },
            ...params
        });
    }


    async getCredentials(domain, userId, params = {}) {
        return await this.request("get", {
            domain,
            url: "/user/" + userId + "/credentials",
            ...params,
        })
    }

    async updateCredentials(domain, userId, body, params = {}) {
        return await this.request("post", {
            domain,
            url: "/user/" + userId + "/credentials",
            body,
            ...params,
        })
    }

    async getUserPermissions(domain, userId, limit = 50, offset = 0, params = {}) {
        const query = "limit=" + limit + "&offset=" + offset;
        return await this.request("get", {
            domain,
            url: "/user/" + userId + "/permission",
            query,
            ...params
        });
    }

    async grantUserPermission(domain, userId, permissionType, resourceDomain, params = {}) {
        return await this.request("put", {
            domain,
            url: "/user/" + userId + "/permission/" + permissionType + "/" + resourceDomain,
            successMsg: "Permission of type " + permissionType + " for domain " + resourceDomain + " granted",
            ...params
        });
    }


    async revokeUserPermission(domain, userId, permissionType, resourceDomain, params = {}) {
        return await this.request("delete", {
            domain,
            url: "/user/" + userId + "/permission/" + permissionType + "/" + resourceDomain,
            successMsg: "Permission of type " + permissionType + " for domain " + resourceDomain + " revoked",
            ...params
        });
    }

    async getUserGroups(domain, userId, limit = 50, offset = 0, params = {}) {
        const query = "limit=" + limit + "&offset=" + offset;
        return await this.request("get", {
            domain,
            url: "/user/" + userId + "/groups",
            query,
            ...params
        });
    }

    async searchGroups(domain, body, params = {}) {
        return await this.request("post", {
            domain,
            url: "/groups",
            body,
            successMsg: null,
            ...params
        })
    }

    async getGroup(domain, groupId, params = {}) {
        return await this.request("get", {
            domain,
            url: "/group/" + groupId,
            ...params
        });
    }

    async createGroup(domain, body, params = {}) {
        const groupName = body?.name;
        return await this.request("post", {
            domain,
            url: "/group",
            body,
            successMsg: "Group " + groupName + " created",
            ...params
        })
    }

    async updateGroup(domain, body, params = {}) {
        const groupId = body?.id;
        const groupName = body?.name;
        return await this.request("put", {
            domain,
            url: "/group/" + groupId,
            body,
            successMsg: "Group " + groupName + " updated",
            ...params
        });
    }

    async deleteGroup(domain, groupId, params = {}) {
        return await this.request("delete", {
            domain,
            url: "/group/" + groupId,
            successMsg: "Group " + groupId + " deleted",
            ...params
        });
    }

    async getGroupAncestors(domain, groupId, limit = 50, offset = 0, params = {}) {
        const query = "limit=" + limit + "&offset=" + offset;
        return await this.request("get", {
            domain,
            url: "/group/" + groupId + "/ancestors",
            query,
            ...params
        });
    }

    async deleteGroupAncestor(domain, groupId, ancestorId, params = {}) {
        return await this.request("delete", {
            domain,
            url: "/group/" + groupId + "/ancestor/" + ancestorId,
            successMsg: "Ancestor " + ancestorId + " of group " + groupId + " deleted",
            ...params
        });
    }

    async createGroupAncestor(domain, groupId, ancestorId, params = {}) {
        return await this.request("put", {
            domain,
            url: "/group/" + groupId + "/ancestor/" + ancestorId,
            successMsg: "Added ancestor " + ancestorId + " of to group " + groupId,
            ...params
        });
    }

    async getGroupPermissions(domain, groupId, limit = 50, offset = 0, params = {}) {
        const query = "limit=" + limit + "&offset=" + offset;
        return await this.request("get", {
            domain,
            url: "/group/" + groupId + "/permissions",
            query,
            ...params
        });
    }

    async grantGroupPermission(domain, groupId, permissionType, resourceDomain, params = {}) {
        return await this.request("put", {
            domain,
            url: "/group/" + groupId + "/permission/" + permissionType + "/" + resourceDomain,
            successMsg: "Permission of type " + permissionType + " for domain " + resourceDomain + " granted",
            ...params
        });
    }


    async revokeGroupPermission(domain, groupId, permissionType, resourceDomain, params = {}) {
        return await this.request("delete", {
            domain,
            url: "/group/" + groupId + "/permission/" + permissionType + "/" + resourceDomain,
            successMsg: "Permission of type " + permissionType + " for domain " + resourceDomain + " revoked",
            ...params
        });
    }

    async getGroupMembers(domain, groupId, limit = 50, offset = 0, params = {}) {
        const query = "limit=" + limit + "&offset=" + offset;
        return await this.request("get", {
            domain,
            url: "/group/" + groupId + "/members",
            query,
            ...params
        });
    }

    async addUserToGroup(domain, userId, groupId, params = {}) {
        return await this.request("put", {
            domain,
            url: "/user/" + userId + "/groups/" + groupId,
            successMsg: "Added user" + userId + " to group " + groupId,
            ...params
        });
    }

    async removeUserFromGroup(domain, userId, groupId, params = {}) {
        return await this.request("delete", {
            domain,
            url: "/user/" + userId + "/groups/" + groupId,
            successMsg: "Removed user" + userId + " to group " + groupId,
            ...params
        });
    }

}

export default CoreUserApiClient;