<template>
  <v-container
    fluid
    class="domain-selector-container"
    data-test-id="domainSelector"
  >
    <!-- DOMAIN PICKER -->
    <v-autocomplete
      dense
      left
      return-object
      item-value="id"
      data-test-id="domainSelectorInput"
      :value="selected"
      :disabled="disabled"
      :items="domains"
      :label="label"
      :solo="!outlined"
      :outlined="outlined"
      :rules="rules"
      :hide-details="hideDetails"
      :menu-props="menuProps"
      :item-text="getDomainName"
      :item-disabled="isDomainDisabled"
      @change="changeDomain"
    >
      <template #item="{ item, on, attrs }">
        <v-list-item
          v-on="on"
          v-bind="attrs"
          :key="item.id"
          @contextmenu.stop.prevent="showContextMenu(item, $event)"
        >
          <v-list-item-title :data-test-id="'domain_' + item.id">
            {{ getHierarchy(item) }}
          </v-list-item-title>
        </v-list-item>
      </template>
      <template #prepend-item>
        <v-list-item>
          <v-spacer />
          <v-btn
            v-if="!disableDomainCreation"
            icon
            color="primary"
            class="add-domain-btn"
            data-test-id="addDomainBtn"
            @click.stop.prevent="createNewDomain"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn
            icon
            data-test-id="reloadDomainsBtn"
            :loading="loading"
            :disabled="loading"
            @click.stop.prevent="loadDomains"
          >
            <v-icon>mdi-sync</v-icon>
          </v-btn>
        </v-list-item>
        <v-divider />
      </template>
    </v-autocomplete>

    <!-- CONTEXT MENU -->
    <v-menu
      absolute
      v-model="contextMenuShown"
      v-if="!disableDomainCreation"
      :position-x="contextMenuX"
      :position-y="contextMenuY"
      data-test-id="domainContextMenu"
    >
      <v-list class="domain-context-menu" dense>
        <v-list-item @click.stop.prevent="openUpsertDialog(true)">
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-title data-test-id="addChildDomainBtn">
            Add child domain
          </v-list-item-title>
        </v-list-item>
        <v-list-item
          v-if="parentDomain && parentDomain.id !== 'system'"
          @click.stop.prevent="openUpsertDialog(false)"
        >
          <v-list-item-icon>
            <v-icon>mdi-pencil</v-icon>
          </v-list-item-icon>
          <v-list-item-title
            class="edit-domain-btn"
            data-test-id="editDomainBtn"
          >
            Edit Domain
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- CREATE DIALOG -->
    <v-dialog v-model="showDialog" persistent max-width="500px">
      <v-card>
        <Domain
          v-if="showDialog"
          :value="editedDomain"
          :parent="parentDomainId"
          @reload="closeDialog(true)"
          @close="closeDialog(false)"
        />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Domain from "./Domain";
import VueRouter from "vue-router";

export default {
  name: "DomainSelector",

  props: {
    value: {
      type: Object,
      required: false,
      default: null,
    },

    onlyMainDomain: {
      required: false,
      default: false,
      type: Boolean,
    },

    domainRange: {
      type: Object,
      required: false,
    },

    disableDomainCreation: {
      type: Boolean,
      required: false,
      default: false,
    },

    label: {
      type: String,
      required: false,
      default: "",
    },

    setLocal: {
      type: Boolean,
      required: false,
      default: false,
    },

    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },

    hideDetails: {
      type: [String, Boolean],
      required: false,
      default: false,
    },

    menuProps: {
      type: Object,
      required: false,
      default: () => {
        return {
          maxHeight: 400,
        };
      },
    },

    rules: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },

    forbiddenDomains: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  components: {
    Domain,
  },

  data() {
    return {
      selected: this.value,
      contextMenuShown: false,
      parentDomain: null,
      editedDomain: null,
      showDialog: false,
      contextMenuX: null,
      contextMenuY: null,
      loading: false,
    };
  },

  async mounted() {
    window.addEventListener("click", function () {
      if (this.contextMenuShown) this.parentDomain = null;
      this.contextMenuShown = false;
    });
  },

  watch: {
    value: {
      handler: function (value) {
        if (!value?.id) return;
        this.selected = this.domains.find((domain) => domain.id === value.id);
      },
      deep: true,
    },
  },

  methods: {
    async loadDomains() {
      try {
        this.loading = true;
        await this.$store.dispatch("loadDomains");
      } finally {
        this.loading = false;
      }
    },

    getHierarchy(domain) {
      const pathToRoot = domain.fullPath.replace(domain.pathPart, "");
      const parentPathParts = pathToRoot.split(".");
      const hierarchyLevels = parentPathParts.reduce(
        (levels, parentPathPart) => {
          const isParentVisible = this.domains.some(
            ({ pathPart }) => pathPart === parentPathPart
          );
          if (isParentVisible) {
            //Only add hierarchy information if the parent is visible
            levels.push("\u00a0\u00A0\u00A0\u00A0");
          }
          return levels;
        },
        []
      );
      const displayName = hierarchyLevels.join("") + this.getDomainName(domain);
      return displayName;
    },

    getDomainName(domain) {
      return (domain.name || domain.pathPart) + " (" + domain.id + ")";
    },

    isDomainDisabled(domain) {
      //check if the domain id is contained in the forbidden domains array
      return this.forbiddenDomains.some((id) => id === domain.id);
    },

    showContextMenu(domain, event) {
      if (this.disableDomainCreation) return;
      this.contextMenuShown = true;
      this.parentDomain = domain;
      this.contextMenuX = event.pageX + 5;
      this.contextMenuY = event.pageY - 70;
    },

    createNewDomain() {
      this.parentDomain = null;
      this.openUpsertDialog(true);
    },

    openUpsertDialog(asNew) {
      if (this.disableDomainCreation) return;
      if (!asNew) {
        this.editedDomain = this.parentDomain;
        this.parentDomain = null;
      }
      this.contextMenuShown = false;
      this.showDialog = true;
    },

    async closeDialog(reload) {
      if (reload) await this.loadDomains();
      this.showDialog = false;
      this.parentDomain = null;
      this.editedDomain = null;
    },

    async changeDomain(domain) {
      const oldDomain = this.domains.find(({ id }) => id === this.selected?.id);
      if (!domain?.id) return;
      if (!this.setLocal) {
        let route = {
          ...this.$route,
          params: {
            ...this.$route.params,
            domain: domain.id,
          },
        };
        if (route.name === "home") this.$set(route, "name", "products");
        try {
          await this.$router.push(route);
          this.selected = domain;
        } catch (error) {
          const { isNavigationFailure, NavigationFailureType } = VueRouter;
          if (isNavigationFailure(error, NavigationFailureType.aborted)) {
            //navigation to new domain was aborted, so reset the domain
            //to the previous one
            this.selected = Object.assign({}, oldDomain);
          }
        }
      } else this.$emit("input", domain);
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    storedDomains() {
      return this.$store?.state?.domains ?? [];
    },

    parentDomainId() {
      return this.parentDomain?.id;
    },

    domains() {
      if (this.onlyMainDomain) {
        return this.storedDomains.filter(
          (domain) =>
            domain.id !== "root" &&
            domain.fullPath.includes(this.selectedDomain)
        );
      }

      let domains = this.storedDomains.filter(
        (domain) => domain?.id !== "root"
      );

      if (this.domainRange) {
        const fromDomainId = this.domainRange?.from?.id;
        if (fromDomainId) {
          const exclusiveFrom = this.domainRange?.from?.exclusive ?? false;
          const parent = domains.find(({ id }) => id === fromDomainId);
          const parentPath = parent?.fullPath;
          domains = domains.filter(({ id, fullPath }) => {
            if (exclusiveFrom && id === fromDomainId) {
              return false;
            }
            return fullPath.startsWith(parentPath);
          });
        }

        const untilDomainId = this.domainRange?.until?.id;
        if (untilDomainId) {
          const exclusiveUntil = this.domainRange?.until?.exclusive ?? false;
          const domain = domains.find(({ id }) => id === untilDomainId);
          const domainpath = domain?.fullPath;
          const untilDomainPathParts = domainpath.split(".");
          domains = domains.filter(({ id, pathPart }) => {
            if (exclusiveUntil && id === untilDomainId) {
              return false;
            }
            return untilDomainPathParts?.includes(pathPart);
          });
        }
      }
      return domains;
    },
  },
};
</script>

<style scoped>
* {
  text-align: left;
}

.domain-selector-container {
  padding: 0;
}

.v-list--dense .v-list-item .add-domain-btn {
  margin-right: 6px;
}

.domain-context-menu {
  background-color: white;
}
</style>