import BaseRestClient from '@/assets/js/api/BaseRestClient'

class CoreConfigurationApiClient extends BaseRestClient {
    constructor(baseURL, errorCallback, successCallback) {
        super(baseURL, "CoreConfigurationApiClient", errorCallback, successCallback);
    }

    async getSchemaRules(domain, { includeParents = true, includeChildren = false } = {}) {
        const schemaRules = await this.request("get", {
            url: "/schemaRule",
            query: "includeParents=" + includeParents + "&includeChildren=" + includeChildren,
            domain,
        });

        return schemaRules ? schemaRules : [];
    }

    async upsertSchemaRule(domain, id, body, params) {
        return this.request("put", {
            url: "/schemaRule/" + id,
            domain,
            body,
            successMsg: "Schema rule " + id + " upserted",
            returnErrors: true,
            ...params
        })
    }

    async getMergedData(domain, keySpace, keyPattern, schema, params = {}) {
        const res = await this.request("post", {
            url: "/data/" + keySpace + "/" + keyPattern + "/forSchema",
            domain,
            body: schema,
            successMsg: null,
            ...params
        });

        if (res?.aborted) return res;

        if (res?.ok) {
            const mergedData = await res.text();
            try {
                //check if mergedData is of type JSON, else return null
                return JSON.parse(mergedData);
            } catch (e) {
                console.warn("GET MERGED DATA FAILED", e);
                return null;
            }
        }
        return null;
    }

    async getPreviewMergedData(domain, keySpace, keyPattern, data, schema = null, params = {}) {
        return this.request("post", {
            url: "/data/" + keySpace + "/" + keyPattern + "/forSchema/preview",
            domain,
            body: {
                data,
                schema
            },
            successMsg: null,
            ...params
        });
    }

    async getData(domain, keySpace, keyPattern, { includeParents = true, signal } = {}) {
        return await this.request("get", {
            url: "/data/" + keySpace + "/" + keyPattern,
            domain,
            query: "includeParents=" + includeParents,
            suppressedErrorCodes: [404],
            signal
        });
    }

    async upsertData(domain, keySpace, keyPattern, body, params = {}) {
        return await this.request("put", {
            url: "/data/" + keySpace + "/" + keyPattern,
            domain,
            body,
            successMsg: "Configuration " + keyPattern + " saved successfully",
            returnErrors: true,
            ...params
        })
    }

    async getAllSchemas(domain, includeParents = false, includeChildren = false) {
        return await this.request("get", {
            url: "/schemaRule",
            domain,
            query: "includeParents=" + includeParents + "&includeChildren=" + includeChildren
        });
    }

    async getMergedSchema(domain, keySpace, keyPattern, { localize = true, signal } = { localize: true }) {
        let query = "keySpace=" + keySpace + "&key=" + keyPattern
        if (localize) query += "&localize=true"
        return await this.request("get", {
            url: "/schemaRule/merge",
            domain,
            query,
            signal
        });

    }

    async getSchemaWithParents(domain, keySpace, keyPattern, { signal } = {}) {
        return await this.request("get", {
            url: "/schemaRule/" + keySpace + "/" + keyPattern + "/withParents",
            domain,
            signal
        })
    }

    async getPreviewMergedSchema(domain, keyPattern, keySpace, body, params = {}) {
        let query = "key=" + keyPattern;
        if (keySpace) query += "&keySpace=" + keySpace
        return await this.request("post", {
            url: "/schemaRule/merge/preview",
            domain,
            body,
            query,
            ...params
        });
    }

    async configLookup(domain, keySpace, keyPattern, { lookupRegion = ["data", "schema"], keyMatching = "all", includeParentDomains = true }) {
        let query = "includeParentDomains=" + includeParentDomains +
            "&keyMatching=" + keyMatching +
            "&keyPattern=" + keyPattern;

        lookupRegion.forEach(region => query += ("&lookupRegion=" + region));
        const res = await this.request("get", {
            domain,
            url: "/lookup/" + keySpace + "/key",
            query,
            suppressedErrorCodes: [404]
        });
        return res;
    }

    async getProductTypeDimensions(domain, productType) {
        return await this.request("get", {
            domain,
            url: "/option/" + productType + "/dimension"
        })
    }

    async getProductTypeDimensionOptions(domain, productType, dimensionKey) {
        return await this.request("get", {
            domain,
            url: "/option/" + productType + "/dimension/" + dimensionKey + "/option"
        })
    }

    async validateData(domain, keySpace, key, body, params = {}) {
        return await this.request("post", {
            url: "/validate/data/" + keySpace + "/" + key,
            domain,
            body,
            successMsg: null,
            returnErrors: true,
            ...params
        })
    }

    async getProductTypes(domain, params = {}) {
        return await this.request("get", {
            url: "/productTypes",
            domain,
            ...params
        });
    }

    async previewValidityRules(body, params = {}) {
        return await this.request("post", {
            url: "/validityRules/preview",
            body,
            returnErrors: true,
            successMsg: null,
            ...params
        })
    }

}

export default CoreConfigurationApiClient;