import { startTimer, stopTimer } from '../utilities/debug'
import updateToken from "../utilities/keycloak/update-token"

/* eslint camelcase: 0 */
class BaseRestClient {
  constructor(baseURL, tracingName, errorCallback, successCallback) {

    this.tracingName = tracingName
    this.errorCallback = errorCallback;
    this.successCallback = successCallback;
    this.baseURL = baseURL;
    this.headers = {
      'Content-Type': 'application/json'
    };
  }

  /**
   * This method performs an API request using the native fetch API
   * @param {String} method The HTTP method which should be used for the request 
   * @param {String} url The API endpoints path (without domain information)
   * @param {String} domain The PSv3 domain in which the resources are located
   * @param {String} query The query parameters string (without "?")
   * @param {Object} body The requests body as JS object
   * @param {String} successMsg Custom success message which will be displayed in the UI once the request was successful
   * @param {Boolean} returnErrors default false. If true, will return the servers response even when request returned with an HTTP error code
   * @param {Array} suppressedErrorCodes A list of error status codes which should not be displayed to the user
   * @param {AbortSignal} signal An AbortControllers signal which enables the functionality to abort the request  
   * @param {Object} additionalRequestOptions An object, whose properties are directly added to the fetch call parameters
   * @param {Boolean} logErrorToConsole default true. Logs any error returned from the API into the browsers console
   * @returns Either the HTTP response object or, if the request method was GET, the response JSON
   */
  async request(method, { url, domain, query, body, successMsg, returnErrors = false, suppressedErrorCodes = [], signal }, additionalRequestOptions = {}, logErrorToConsole = true) {
    try {
      const token = await updateToken();
      this.headers["Authorization"] = "Bearer " + token;
      const requestPath = this.baseURL + "/" + (domain ? domain : "") + url;
      //translate method to upper case to prevent issues with PATCH, see https://github.com/whatwg/fetch/issues/50
      const requestMethod = method.toUpperCase();
      const startTime = startTimer();
      const response = await fetch(
        requestPath + (query ? "?" + query : ""),
        {
          method: requestMethod,
          headers: this.headers,
          body: JSON.stringify(body),
          signal,
          ...additionalRequestOptions
        });

      const stopTime = stopTimer(startTime);
      if (process.server) {
        console.log('Rest query took', stopTime, url);
      }

      if (!response?.ok) {
        //ERROR HANDLING
        if (returnErrors) return response;

        const statusCode = response.status;
        if (suppressedErrorCodes?.includes(statusCode)) return null;

        let error;
        try {
          //Try to parse the error response 
          error = await response.json();
        } catch (e) {
          console.warn("Error response could not be parsed", e);
          error = null;
        }
        throw Error(statusCode + " - " + (error?.message ?? "An unexpected error occured"));
      }

      if (successMsg && typeof this.successCallback === 'function') {
        this.successCallback(successMsg);
      }

      return (requestMethod === 'GET') ? await response.json() : response;
    }
    catch (e) {
      if (logErrorToConsole !== false) {
        console.log(
          'Error while handling data',
          method, url, body, e);
      }
      //If an abort signal is given and the request was aborted,
      //return a flag to signal that the abort was successful.
      if (e.name === "AbortError" && signal) return { aborted: true }
      if (typeof this.errorCallback === 'function') this.errorCallback(e);
      else
        throw e;
    }
  }
}

export default BaseRestClient
