<template>
  <v-dialog v-model="dialog" :width="width" persistent class="prompt-dialog">
    <v-card class="prompt-dialog-card">
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <v-form @submit.prevent="set" ref="promptForm">
          <v-select
            v-if="hasOptions"
            v-model="value"
            dense
            outlined
            ref="input"
            data-test-id="promptSelectField"
            item-text="text"
            item-value="value"
            :rules="rules"
            :items="options"
          />

          <v-text-field
            v-else
            v-model="value"
            dense
            outlined
            :rules="rules"
            ref="input"
            data-test-id="promptTextField"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="primary" @click="set" data-test-id="promptOkBtn">
          OK
        </v-btn>
        <v-btn text @click="cancel" data-test-id="promptCancelBtn">
          CANCEL
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import vuetify from "@/plugins/vuetify";

export default {
  vuetify: vuetify,

  props: {
    //The title of the dialog
    title: {
      type: String,
      required: false,
      default: "Enter value",
    },

    //dialog width
    width: {
      type: Number,
      required: false,
      default: 400,
    },

    //Rules for the text input
    rules: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },

    options: {
      type: Array,
      required: false,
    },
  },

  data() {
    return {
      value: undefined,
      dialog: false,
      resolve: null,
      reject: null,
    };
  },

  methods: {
    open() {
      this.dialog = true;
      setTimeout(() => {
        //focus the dialogs input
        this.$refs.input.focus();
      });
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },

    set() {
      if (!this.$refs.promptForm.validate()) return;
      this.resolve(this.value);
      this.dialog = false;
    },

    cancel() {
      this.resolve(undefined);
      this.dialog = false;
    },
  },

  computed: {
    hasOptions() {
      return !!this.options && Array.isArray(this.options);
    },
  },
};
</script>