var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],ref:"application",attrs:{"data-test-id":"application"}},[_c('v-app-bar',{ref:"appbar",class:{
      'main-app-bar': true,
      'has-domain': !!_vm.selectedDomain,
    },attrs:{"app":""}},[_c('DomainSelector',{staticClass:"domain-selector",attrs:{"label":"Select a domain...","hide-details":true,"value":{
        id: _vm.selectedDomain,
      },"menu-props":{
        maxHeight: 600,
      }}}),_c('v-spacer',{staticClass:"app-bar-spacer"}),(_vm.selectedDomain)?_c('div',{staticClass:"menu-tabs-group"},_vm._l((_vm.tabs),function(tab){return _c('a',{directives:[{name:"ripple",rawName:"v-ripple"}],key:tab.name,class:{
          'menu-tab v-tab': true,
          selected: _vm.selectedTab.name === tab.name,
        },attrs:{"data-test-id":tab.name + '_tab',"href":_vm.getMenuLink(tab.route)},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openMenu(tab)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(tab.icon))]),_vm._v(" "+_vm._s(tab.name.toUpperCase())+" ")],1)}),0):_vm._e(),(_vm.selectedDomain)?_c('v-menu',{ref:"appsMenu",attrs:{"offset-y":"","bottom":"","max-height":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"apps-button",attrs:{"data-test-id":"appsButton"}},'div',attrs,false),on),[_c('v-icon',[_vm._v("mdi-apps")])],1)]}}],null,false,3808064955)},[_c('div',{staticClass:"apps-menu"},_vm._l((_vm.menus),function(menu,index){return _c('a',{directives:[{name:"ripple",rawName:"v-ripple"}],key:index + menu.name,class:{
            'apps-menu-item v-tab': true,
            selected: _vm.selectedTab.name === menu.name,
          },attrs:{"title":menu.name,"href":_vm.getMenuLink(menu.route),"data-test-id":menu.name + 'MenuItem'},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openMenu(menu)}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(_vm._s(menu.icon))]),_c('div',{staticClass:"menu-name"},[_vm._v(_vm._s(menu.name))])],1)}),0)]):_vm._e(),_c('v-menu',{attrs:{"open-on-click":"","bottom":"","offset-y":"","close-on-content-click":false,"min-width":200},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"user-profile-btn",attrs:{"data-test-id":"userProfileBtn","title":"Show user profile"}},'a',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-box-outline")])],1)]}}])},[_c('v-card',{staticClass:"user-profile-card",attrs:{"data-test-id":"userProfile"}},[_c('div',{staticClass:"user-profile-container"},[_c('div',{staticClass:"mb-3"},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-account-box")])],1),_c('div',{staticClass:"font-weight-bold",attrs:{"data-test-id":"currentUser"}},[_vm._v(" "+_vm._s(_vm.username)+" ")])]),_c('v-divider'),_c('v-btn',{attrs:{"text":"","small":"","block":"","tile":"","depressed":"","data-test-id":"userAccountSettingsBtn","title":"Open account management"},on:{"click":_vm.openAccountManagement}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-cog")]),_vm._v(" Account settings ")],1),_c('v-divider'),_c('v-btn',{attrs:{"text":"","small":"","block":"","tile":"","depressed":"","color":"primary","title":"Log out","data-test-id":"userLogoutBtn"},on:{"click":_vm.logout}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-logout")]),_vm._v(" Logout ")],1)],1)],1),_c('v-container',{class:{
        'ps-logo-container': true,
        small: _vm.windowSize.x < 1441,
      }},[(_vm.windowSize.x < 1441)?_c('img',{staticClass:"ps-logo-small",attrs:{"src":require("./assets/img/logo_small.png")}}):_c('img',{staticClass:"ps-logo",attrs:{"src":require("./assets/img/logo.png")}})])],1),_c('v-main',{staticClass:"main-page",attrs:{"data-test-id":"mainPage"}},[_c('v-container',{staticClass:"pa-0",style:({
        height: _vm.pageHeight + 'px',
        maxHeight: _vm.pageHeight + 'px',
      }),attrs:{"fluid":""}},[(_vm.selectedDomain)?_c('router-view',{key:_vm.selectedDomain}):_vm._e()],1),_c('div',{staticClass:"version-information"},[_vm._v(" Build: "+_vm._s(_vm.currentBuildVersion)+" ("+_vm._s(_vm.currentBuildTimestamp)+") ")])],1),_c('v-snackbar',{staticClass:"udpate-notification-snackbar",attrs:{"bottom":"","value":_vm.updateExists,"timeout":-1},scopedSlots:_vm._u([{key:"action",fn:function(ref){
      var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"psgreen","text":""},on:{"click":_vm.refreshApp}},'v-btn',attrs,false),[_vm._v(" Update ")])]}}])},[_vm._v(" A new version is available ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }