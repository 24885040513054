<template>
  <v-app v-resize="onResize" data-test-id="application" ref="application">
    <v-app-bar
      ref="appbar"
      app
      :class="{
        'main-app-bar': true,
        'has-domain': !!selectedDomain,
      }"
    >
      <DomainSelector
        label="Select a domain..."
        class="domain-selector"
        :hide-details="true"
        :value="{
          id: selectedDomain,
        }"
        :menu-props="{
          maxHeight: 600,
        }"
      />
      <v-spacer class="app-bar-spacer" />
      <div v-if="selectedDomain" class="menu-tabs-group">
        <a
          v-for="tab in tabs"
          v-ripple
          :key="tab.name"
          :data-test-id="tab.name + '_tab'"
          :class="{
            'menu-tab v-tab': true,
            selected: selectedTab.name === tab.name,
          }"
          :href="getMenuLink(tab.route)"
          @click.stop.prevent="openMenu(tab)"
        >
          <v-icon left>{{ tab.icon }}</v-icon>
          {{ tab.name.toUpperCase() }}
        </a>
      </div>
      <v-menu
        v-if="selectedDomain"
        offset-y
        bottom
        max-height="600px"
        ref="appsMenu"
      >
        <template #activator="{ on, attrs }">
          <div
            v-ripple
            v-on="on"
            v-bind="attrs"
            class="apps-button"
            data-test-id="appsButton"
          >
            <v-icon>mdi-apps</v-icon>
          </div>
        </template>
        <div class="apps-menu">
          <a
            v-for="(menu, index) in menus"
            v-ripple
            :key="index + menu.name"
            :class="{
              'apps-menu-item v-tab': true,
              selected: selectedTab.name === menu.name,
            }"
            :title="menu.name"
            :href="getMenuLink(menu.route)"
            :data-test-id="menu.name + 'MenuItem'"
            @click.stop.prevent="openMenu(menu)"
          >
            <v-icon large>{{ menu.icon }}</v-icon>
            <div class="menu-name">{{ menu.name }}</div>
          </a>
        </div>
      </v-menu>

      <!-- User Profile -->
      <v-menu
        open-on-click
        bottom
        offset-y
        :close-on-content-click="false"
        :min-width="200"
      >
        <template #activator="{ on, attrs }">
          <a
            v-ripple
            v-bind="attrs"
            v-on="on"
            class="user-profile-btn"
            data-test-id="userProfileBtn"
            title="Show user profile"
          >
            <v-icon>mdi-account-box-outline</v-icon>
          </a>
        </template>
        <v-card class="user-profile-card" data-test-id="userProfile">
          <div class="user-profile-container">
            <div class="mb-3">
              <v-icon large>mdi-account-box</v-icon>
            </div>
            <div class="font-weight-bold" data-test-id="currentUser">
              {{ username }}
            </div>
          </div>
          <v-divider />
          <v-btn
            text
            small
            block
            tile
            depressed
            data-test-id="userAccountSettingsBtn"
            title="Open account management"
            @click="openAccountManagement"
          >
            <v-icon left>mdi-account-cog</v-icon>
            Account settings
          </v-btn>
          <v-divider />
          <v-btn
            text
            small
            block
            tile
            depressed
            color="primary"
            title="Log out"
            data-test-id="userLogoutBtn"
            @click="logout"
          >
            <v-icon left>mdi-logout</v-icon>
            Logout
          </v-btn>
        </v-card>
      </v-menu>
      <v-container
        :class="{
          'ps-logo-container': true,
          small: windowSize.x < 1441,
        }"
      >
        <img
          v-if="windowSize.x < 1441"
          src="./assets/img/logo_small.png"
          class="ps-logo-small"
        />
        <img v-else src="./assets/img/logo.png" class="ps-logo" />
      </v-container>
    </v-app-bar>
    <v-main data-test-id="mainPage" class="main-page">
      <v-container
        fluid
        class="pa-0"
        :style="{
          height: pageHeight + 'px',
          maxHeight: pageHeight + 'px',
        }"
      >
        <router-view v-if="selectedDomain" :key="selectedDomain" />
      </v-container>
      <div class="version-information">
        Build: {{ currentBuildVersion }} ({{ currentBuildTimestamp }})
      </div>
    </v-main>
    <v-snackbar
      bottom
      class="udpate-notification-snackbar"
      :value="updateExists"
      :timeout="-1"
    >
      A new version is available
      <template v-slot:action="{ attrs }">
        <v-btn color="psgreen" text v-bind="attrs" @click="refreshApp">
          Update
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import DomainSelector from "components/domain/DomainSelector";
import MessageSnackbar from "components/MessageSnackbar";
import updateMixin from "mixins/update";
import Vue from "vue";
//Import tab components so that its styles are available when app is opened
import { VTabs, VTab } from "vuetify/lib";

export default {
  mixins: [updateMixin],
  name: "App",
  VTabs,
  VTab,

  components: {
    DomainSelector,
  },

  provide() {
    return {
      getPageHeight: () => {
        return this.pageHeight;
      },

      getPageWidth: () => {
        return this.pageWidth;
      },
    };
  },

  data() {
    return {
      errorSnackbar: false,
      successSnackbar: false,
      errorTimeout: 0,
      successTimeout: 0,
      rebuildMessages: 0,
      snackbars: [],
      windowSize: {
        x: 0,
        y: 0,
      },
      isAdmin: false,
    };
  },

  async mounted() {
    await this.$store.dispatch("loadDomains");
    const route = this.$route;
    if (route.name === "notfound") {
      this.$store.commit(
        "SET_ERROR",
        "Error: No route with path " + route.path + " found!"
      );
      this.$router.replace({ name: "home" });
      return;
    }
    const domain = route?.params?.domain;
    if (domain) {
      //update currently selected domain in store with domain of path
      this.setGlobalDomain(domain);
    }
  },

  watch: {
    $route: async function (route, oldRoute) {
      this.isAdmin = await this.$keycloak.hasRealmRole("Admin");
      const isAdminRoute = route.matched.some(
        ({ meta }) => meta.adminOnly === true
      );
      if (route.name === "notfound" || (!this.isAdmin && isAdminRoute)) {
        this.$store.commit("SET_ERROR", "Error: Page not found");
        this.$router.replace({ name: "home" });
        return;
      }

      const domain = route.params.domain;
      if (!domain) {
        //show products page per default
        await this.$router.push({
          name: "products",
          params: { domain: this.selectedDomain },
        });
      } else if (this.selectedDomain !== domain) {
        //domain has been changed
        let newRoute = {
          params: {
            domain,
          },
        };

        const keepURLParams =
          !this.selectedDomain ||
          route?.params?.keepParams ||
          route.matched.some(
            ({ meta }) => meta.keepParamsOnDomainChange === true
          );
        if (keepURLParams) {
          const params = { ...route.params, domain };
          delete params?.keepParams;
          newRoute = {
            ...route,
            params,
          };
          await this.$router.replace(newRoute);
        } else {
          //navigate to the immediate parent of the current route when the domain is changed
          //and the last route was not the configuration editor or the translation UI
          const idx = route?.matched ? route.matched.length - 2 : 0;
          const name = route?.matched?.[idx]?.name;
          if (name) newRoute.name = name;
          await this.$router.replace(newRoute);
        }
      }

      //Reload the localizations if the menu or the domain has been changed
      if (
        (!route.matched.some(({ name }) => name === oldRoute.name) ||
          route.params?.domain !== oldRoute.params?.domain) &&
        !route.matched.some(({ name }) => name === "localizations")
      ) {
        this.$store.dispatch(
          "loadLocalizationConfiguration",
          route.params.domain
        );
      }

      //update currently selected domain in store with domain of path
      this.setGlobalDomain(route.params.domain);
    },

    messages(messages) {
      //remove all existing snackbars
      this.snackbars.forEach((id) => {
        const snackbar = document.getElementById(id)?.__vue__;
        if (!snackbar) return;
        snackbar.$destroy();
        snackbar.$el.parentNode.removeChild(snackbar.$el);
      });
      this.snackbars = [];
      if (messages.length === 0) return;

      const Snackbar = Vue.extend(MessageSnackbar);
      //create one snackbar per message
      messages.forEach((msg) => {
        const snackbar = new Snackbar({
          propsData: {
            message: msg.text,
            type: msg.type,
            msgShowTime: msg.msgShowTime,
          },
        }).$mount();

        snackbar.$on("closed", () => {
          //destroy the vue listeners, etc
          //and remove the element from the DOM
          snackbar.$destroy();
          snackbar.$el.parentNode.removeChild(snackbar.$el);

          //dismiss the displayed message
          let action =
            msg.type === "error" ? "DISMISS_ERROR" : "DISMISS_SUCCESS";
          this.$store.commit(action, msg.index);
        });

        let el = snackbar.$el;
        let id = snackbar._uid;
        this.snackbars.push(id);
        el.id = id;
        el.style.marginBottom = (this.snackbars.length - 1) * 100 + "px";
        document.getElementById("app").appendChild(el);
      });
    },
  },

  methods: {
    onResize() {
      this.$set(this.windowSize, "x", window.innerWidth);
      this.$set(this.windowSize, "y", window.innerHeight);
    },

    setGlobalDomain(id) {
      //enhances the beforeEach guard in router.js
      //and is also called when the Application is initially mounted.
      //Because of later, we also need the same functionality here

      if (!id) return;

      if (this.isExistingDomain(id)) {
        this.$store.commit("SET_SELECTED_DOMAIN", id);
        return;
      }

      this.$store.dispatch("loadDomains").then(() => {
        if (this.isExistingDomain(id)) {
          this.$store.commit("SET_SELECTED_DOMAIN", id);
          return;
        }

        this.$store.commit(
          "SET_ERROR",
          "Error: Domain with id " + id + " could not be found"
        );
        this.$router.replace({ name: "home" });
      });
    },

    isExistingDomain(domainId) {
      return this.storedDomains.some((domain) => domain.id === domainId);
    },

    openMenu(menu) {
      const route = menu.route;
      if (this.$route.matched.some(({ name }) => name === route)) {
        return;
      }
      this.$router.push(this.getRouteObject(route));
      this.$nextTick(() => {
        //Close apps menu
        const menu = this.$refs?.appsMenu;
        if (menu) menu.save();
      });
    },

    getRouteObject(route) {
      return {
        name: route,
        params: {
          domain: this.selectedDomain,
        },
      };
    },

    getMenuLink(route) {
      return this.$router.resolve(this.getRouteObject(route)).href;
    },

    logout() {
      this.$keycloak.logout();
    },

    openAccountManagement() {
      this.$keycloak.accountManagement();
    },
  },

  computed: {
    pageHeight() {
      return this.windowSize.y - 64 - 24;
    },

    pageWidth() {
      return this.windowSize.x;
    },

    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    storedDomains() {
      return this.$store.state.domains;
    },

    menus() {
      const menus = [
        {
          name: "Products",
          route: "products",
          icon: "mdi-ticket",
        },
        {
          name: "Orders",
          route: "orders",
          icon: "mdi-package-variant",
        },
        {
          name: "Customers",
          route: "customers",
          icon: "mdi-account-circle",
        },
        {
          name: "Configuration",
          route: "configuration",
          icon: "mdi-cog",
        },
        {
          name: "Catalogs",
          route: "catalogs",
          icon: "mdi-book-open-variant",
        },
        {
          name: "Cart-Rules",
          route: "cartRules",
          icon: "mdi-cart-check",
        },
        {
          name: "Localization",
          route: "localizations",
          icon: "mdi-translate",
        },
        {
          name: "Connectors",
          route: "connectors",
          icon: "mdi-connection",
        },
        {
          name: "Specs",
          route: "specs",
          icon: "mdi-file-cog",
        },
        {
          name: "Resellers",
          route: "resellers",
          icon: "mdi-store",
        },
        {
          name: "Service Providers",
          route: "serviceProviders",
          icon: "mdi-hand-coin",
        },
        {
          name: "Contracts",
          route: "contracts",
          icon: "mdi-file-sign",
        },
        {
          name: "Logs",
          route: "logs",
          icon: "mdi-text-box",
        },
        {
          name: "User Management",
          route: "userManagement",
          icon: "mdi-shield-account",
        },
      ];

      return menus.filter(({ name, route }) => {
        const routeObj = this.$router.resolve({ name: route });
        const adminAccessOnly = !!routeObj?.route?.meta?.adminOnly;
        if (adminAccessOnly && !this.isAdmin) {
          return false;
        }
        if (name === "Connectors" && this.selectedDomain !== "system") {
          return false;
        }
        return true;
      });
    },

    tabs() {
      let defaultTabs = this.menus.slice(0, 5);
      const selected = this.selectedTab.name;
      if (selected && !defaultTabs.some(({ name }) => selected === name)) {
        const selectedTab = this.menus.find(({ name }) => selected === name);
        defaultTabs.splice(4, 1, selectedTab);
      }

      const displayWidth = this.windowSize.x;
      if (displayWidth >= 720) {
        //Hide the tabs according to display width
        //but always show the current tab
        let selectedIdx = defaultTabs.findIndex(
          (tab) => tab?.name === selected
        );

        if (displayWidth <= 900) {
          defaultTabs = [this.selectedTab];
        } else if (displayWidth <= 1050) {
          //Display 2 tabs, so remove the three two not selected tabs
          switch (selectedIdx) {
            case 2:
              //selected is the third tab
              defaultTabs.splice(1, 1);
              defaultTabs.splice(3, 2);
              break;
            case 3:
              //selected is second last tab
              defaultTabs.splice(1, 2);
              defaultTabs.splice(2, 1);
              break;
            case 4:
              //selected is the last tab
              defaultTabs.splice(1, 3);
              break;
            default:
              //selected is on of the first two tabs
              defaultTabs.splice(2, 3);
          }
        } else if (displayWidth <= 1200) {
          //Display 3 tabs, so remove the last two not selected tabs
          switch (selectedIdx) {
            case 3:
              //selected is second last tab
              defaultTabs.splice(4, 1);
              defaultTabs.splice(2, 1);
              break;
            case 4:
              //selected is last tab
              defaultTabs.splice(2, 2);
              break;
            default:
              //selected is on of the first three tabs
              defaultTabs.splice(3, 2);
          }
        } else if (displayWidth <= 1600) {
          //Display 4 tabs, so remove the last not selected tab
          let indexToDel = selectedIdx === 4 ? 3 : 4;
          defaultTabs.splice(indexToDel, 1);
        }
      }
      return defaultTabs;
    },

    selectedTab() {
      const tab = this.menus.find(({ route }) => {
        return this.$route.matched.some(({ name }) => name === route);
      });
      return tab ?? {};
    },

    messages() {
      let successMessages = this.$store.state.successMessages;
      let errorMessages = this.$store.state.errorMessages;
      let messages = [];

      //Add success messages
      successMessages.forEach((msg, index) => {
        if (!msg || index > 5) return;
        messages.push({
          text: msg.message,
          date: msg.date,
          type: "success",
          msgShowTime: msg.msgShowTime,
          index,
        });
      });

      //Add error messages
      errorMessages.forEach((msg, index) => {
        if (!msg || index > 5) return;
        messages.push({
          text: msg.message,
          date: msg.date,
          type: "error",
          msgShowTime: msg.msgShowTime ? msg.msgShowTime : 0,
          index,
        });
      });

      //sort them by their occurance date
      return messages.sort((a, b) => a.date.getTime() - b.date.getTime());
    },

    username() {
      return this.$keycloak?.tokenParsed?.preferred_username;
    },

    currentBuildVersion() {
      const version = process.env.VUE_APP_VERSION;
      console.log("Build Version", version);
      return version ?? "No version information";
    },

    currentBuildTimestamp() {
      const timestamp = process.env.VUE_APP_VERSION_TIMESTAMP;
      console.log("Build Timestamp", timestamp);
      if (!timestamp) return "null";
      return this.$getLocalizedDate(timestamp);
    },
  },
};
</script>

<style scoped>
/* 
.main-app-bar.v-app-bar.v-toolbar.v-sheet::v-deep > .v-toolbar__content{
    background: linear-gradient(-45deg, var(--v-psblue-base) 33%, var(--v-slpink-base) 33%, var(--v-slpink-base) 66%, var(--v-psgreen-base) 66%);
}
*/

.main-app-bar.v-app-bar.v-toolbar::v-deep > .v-toolbar__content {
  padding: 0px 16px;
  max-width: 100%;
}

.domain-selector {
  display: flex;
  flex-grow: 1;
  max-width: 350px;
  margin-left: unset;
  margin-right: 12px;
}

.ps-logo-container {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 48px;
  background: none;
  max-width: fit-content;
  padding: 6px;
}

.ps-logo-container.small {
  padding: 2px;
}

.ps-logo {
  width: 200px;
  height: 36px;
}

.ps-logo-small {
  width: 64px;
  height: 48px;
}

.menu-tabs-group {
  display: flex;
  flex-grow: 1;
  height: 100%;
  flex-wrap: wrap-reverse;
  overflow: hidden;
}

.menu-tabs-group > .menu-tab,
.apps-button {
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 12px;
}

.menu-tabs-group > .menu-tab {
  min-width: 90px;
  max-width: unset;
}

.menu-tabs-group > .menu-tab.selected {
  border-bottom: 2px solid var(--v-primary-base);
}

.menu-tabs-group > .menu-tab.selected,
.menu-tabs-group > .menu-tab.selected > .v-icon {
  color: var(--v-primary-base);
}

.apps-button {
  width: 64px;
  max-width: 64px;
}

.apps-menu {
  display: flex;
  max-width: 600px;
  background-color: white;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 12px;
  padding: 12px;
}

.apps-menu > .apps-menu-item {
  display: flex;
  flex-direction: column;
  height: 80px;
  min-height: 80px;
  width: 164px;
  max-width: 164px;
  justify-content: center;
  align-items: center;
}

.apps-menu > .apps-menu-item > .v-icon {
  margin-bottom: 12px;
}

.apps-menu > .apps-menu-item > .menu-name {
  font-size: 0.75rem;
}

.apps-menu > .apps-menu-item.selected,
.apps-menu > .apps-menu-item.selected > .v-icon {
  color: var(--v-primary-base) !important;
}

.apps-button:hover,
.menu-tabs-group > .menu-tab:hover,
.apps-menu > .apps-menu-item:hover,
.apps-button:focus,
.menu-tabs-group > .menu-tab:focus,
.apps-menu > .apps-menu-item:focus,
.user-profile-btn:focus,
.user-profile-btn:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.v-window-item,
.v-tabs-items {
  height: 100%;
}

.main-app-bar.has-domain .app-bar-spacer {
  display: none;
}

@media only screen and (max-width: 719px) {
  .menu-tabs-group {
    display: none;
  }

  .main-app-bar .app-bar-spacer {
    display: flex !important;
  }
}

@media only screen and (max-width: 450px) {
  .ps-logo-container {
    display: none;
  }
}

.main-app-bar .user-profile-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 8px;
  cursor: pointer;
}

.user-profile-card > .user-profile-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding: 12px;
}

.user-profile-card > .v-btn {
  justify-content: left;
}

.main-page {
  padding-top: 64px !important;
}

.main-page .version-information {
  height: 24px;
  padding: 0px 12px;
  display: flex;
  flex-grow: 1;
  font-size: 0.6rem !important;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.0333333333em !important;
  color: rgba(0, 0, 0, 0.6) !important;
}
</style>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.close-dialog-btn {
  margin-left: 5px;
}

.button-loader {
  margin-right: 8px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr.selected {
  background-color: var(--v-psblue-base);
  color: white;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr.selected:hover {
  color: black;
}

/* All buttons with red or green background should have white text */
.v-btn.green:not(.v-btn--outlined),
.v-btn.red:not(.v-btn--outlined) {
  color: white !important;
}

.help-tooltip {
  max-width: 500px;
  word-break: normal;
  overflow-wrap: anywhere;
}
</style>
