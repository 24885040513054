import Vuex from 'vuex'
import Vue from 'vue'

//load Vuex
Vue.use(Vuex);

const state = () => ({
    supportedLocales: [],
    fallbackLocale: null,
});

const actions = {
    async loadLocalizationConfiguration({ commit, rootState }, domain) {
        //get all supported locales specified in the core.configService configuration
        const res = await Vuex.Store.prototype.$coreApi.coreConfigurationApi.getMergedData(
            domain ?? rootState.selectedDomain,
            "Configuration",
            "core.configService",
            null,
            {
                returnErrors: true
            }
        );

        const configData = res?.data;
        if (!configData) {
            console.warn("Could not load localization configuration");
            return;
        }
        const translationData = configData?.localization;
        const supportedLocales = translationData?.supportedLocales ?? [];
        commit("SET_SUPPORTED_LOCALES", supportedLocales);
        commit("SET_FALLBACK_LOCALE", translationData?.fallbackLocale);
    }
}

const mutations = {

    SET_SUPPORTED_LOCALES(state, supportedLocales) {
        state.supportedLocales = supportedLocales;
    },

    SET_FALLBACK_LOCALE(state, fallbackLocale) {
        state.fallbackLocale = fallbackLocale;
    },
}


const module = {
    state,
    actions,
    mutations
};


export default module;