import BaseRestClient from '@/assets/js/api/BaseRestClient'

class CoreConnectorApiClient extends BaseRestClient {
    constructor(baseURL, errorCallback, successCallback) {
        super(baseURL, "CoreConnectorApiClient", errorCallback, successCallback);
    }

    async getConnectors() {
        return await this.request("get", {
            url: "connector",
        });
    }

    async getConnector(id) {
        return await this.request("get", {
            url: "connector/" + id,
            suppressedErrorCodes: [404]
        });
    }

    async upsertConnector(connector, successMsg) {
        return await this.request("put", {
            url: "connector/" + connector.id,
            body: connector,
            successMsg: successMsg ?? "Connector " + connector.id + " updated",
        });
    }

    async deleteConnector(id, successMsg) {
        return await this.request("delete", {
            url: "connector/" + id,
            successMsg: successMsg ?? "Connector " + id + " deleted",
        });
    }

    async getSpecs(domain) {
        return await this.request("get", {
            url: "/spec",
            domain,
        });
    }

    async getSpec(domain, id) {
        return await this.request("get", {
            url: "/spec/" + id,
            domain,
            suppressedErrorCodes: [404]
        });
    }

    async upsertSpec(domain, spec, successMsg) {
        return await this.request("put", {
            url: "/spec/" + spec.id,
            domain,
            body: spec,
            successMsg: successMsg ?? "Spec " + spec.id + " updated",
        })
    }

    async getServiceProviders(domain) {
        return await this.request("get", {
            domain,
            url: "/serviceproviders"
        });
    }


    async getImportJobs(domain, importConfigId) {
        let query = "";
        if (importConfigId) query += "importConfigId=" + importConfigId;
        return await this.request("get", {
            domain,
            url: "/imports",
            query
        })
    }

    async startImport(domain, specId, { name, id }, successMsg) {
        return await this.request("post", {
            domain,
            url: "/spec/" + specId + "/config/" + id + "/import",
            successMsg: successMsg ?? "Import for " + name + " started"
        });
    }

    async startPreload(domain, specId, body, force, params = {}) {
        const method = force ? "forcePreloadProducts" : "preloadProducts"
        return await this.request("post", {
            domain,
            url: "/spec/" + specId + "/ui/" + method,
            body,
            successMsg: "Startet preload of product variations",
            ...params
        })
    }

    async cancelImport(domain, importJobId) {
        return await this.request("post", {
            domain,
            url: "/import/" + importJobId + "/cancel",
            successMsg: "Import canceled"
        })
    }

    async getPreloadStatus(domain, specId, body, params = {}) {
        return await this.request("post", {
            domain,
            url: "/spec/" + specId + "/ui/preloadStatus",
            body,
            successMsg: null,
            suppressedErrorCodes: [503],
            ...params
        })
    }

    async uiInput(domain, specId, importConfigurationId, params = {}) {
        return await this.request("post", {
            domain,
            url: "/spec/" + specId + "/importConfiguration/" + importConfigurationId + "/ui/input",
            successMsg: "Loaded product variations",
            ...params
        })
    }

    async upsertImportConfiguration(domain, specId, importConfiguration, successMsg) {
        return await this.request("put", {
            domain,
            url: "/spec/" + specId + "/importConfiguration/" + importConfiguration.id,
            body: importConfiguration,
            successMsg: successMsg ?? "Import configuration " + importConfiguration.name + " saved",
            returnErrors: true
        })
    }

    async deleteImportConfiguration(domain, specId, { id, name }, successMsg) {
        return await this.request("delete", {
            domain,
            url: "/spec/" + specId + "/importConfiguration/" + id,
            successMsg: successMsg ?? "Import configuration " + name + " deleted",
        })
    }




}

export default CoreConnectorApiClient;