<template>
  <div
    v-if="shown"
    class="message-container"
    :style="{
      'z-index': getMaxZIndex(),
    }"
  >
    <div
      :class="{
        message: true,
        'elevation-12': true,
        [type]: true,
      }"
    >
      <div class="message-body">
        <v-icon :color="color" left>
          {{ isError ? "mdi-alert" : "mdi-check-circle-outline" }}
        </v-icon>
        <div
          :data-test-id="isError ? 'errorMsg' : 'successMsg'"
          class="text-truncate"
          :title="message"
          v-html="displayedMessage"
        />
      </div>
      <div class="message-action">
        <v-btn
          text
          data-test-id="messageDismissBtn"
          :color="color"
          @click="$emit('closed')"
        >
          DISMISS
        </v-btn>
      </div>

      <!-- Workaround to prevent vuetify dialogs from closing when dismissing the messages -->
      <div
        class="v-menu__content--active"
        :style="{
          display: 'none',
          'z-index': getMaxZIndex(),
        }"
      ></div>
    </div>
  </div>
</template>

<script>
import vuetify from "@/plugins/vuetify";

export default {
  vuetify,

  props: {
    message: {
      type: String,
      required: true,
    },

    type: {
      type: String,
      required: true,
    },

    msgShowTime: {
      type: Number,
      required: false,
      default: 7000,
    },
  },

  data() {
    return {
      shown: true,
    };
  },

  mounted() {
    if (this.msgShowTime > 0)
      window.setTimeout(() => (this.shown = false), this.msgShowTime);
  },

  watch: {
    shown(isShown) {
      if (!isShown) this.$emit("closed");
    },
  },

  methods: {
    getMaxZIndex() {
      //get the curretn highest z-index on the page to make sure
      //that the message is always visible
      const maxZ = Array.from(document.querySelectorAll("body *"))
        .map((a) => parseFloat(window.getComputedStyle(a).zIndex))
        .filter((a) => !isNaN(a))
        .sort((a, b) => a - b)
        .pop();
      return maxZ;
    },
  },

  computed: {
    isError() {
      return this.type === "error";
    },

    color() {
      return this.isError ? "red" : "green";
    },

    displayedMessage() {
      return this.$sanitize(this.message, {
        FORBID_TAGS: ["style", "input", "textarea", "button", "form"],
        FORBID_ATTR: ["style", "class"],
      });
    },
  },
};
</script>

<style scoped>
.message-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: fixed;
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100%;
  font-size: 0.875rem;
  pointer-events: none;
  top: auto;
  right: 0;
  bottom: 0;
  left: auto;
  z-index: 1000;
}

.message-container > .message {
  display: flex;
  min-height: 80px;
  background-color: white;
  border-color: white;
  margin: 8px 0 8px 8px;
  min-width: 400px;
  max-width: 800px;
  position: relative;
  pointer-events: auto;
  padding: 12px;
  transition-duration: 0.15s;
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.message-container > .message.error {
  background-color: white !important;
  border: 1px solid red;
  border-left: 5px solid red;
}

.message-container > .message.success {
  background-color: white !important;
  border: 1px solid green;
  border-left: 5px solid green;
}

.message-container > .message > .message-body {
  display: flex;
  flex-flow: row;
  flex-grow: 4;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 8px;
  max-width: calc(100% - 100px);
}

.message-container > .message > .message-action {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>