var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.shown)?_c('div',{staticClass:"message-container",style:({
    'z-index': _vm.getMaxZIndex(),
  })},[_c('div',{class:( _obj = {
      message: true,
      'elevation-12': true
    }, _obj[_vm.type] = true, _obj )},[_c('div',{staticClass:"message-body"},[_c('v-icon',{attrs:{"color":_vm.color,"left":""}},[_vm._v(" "+_vm._s(_vm.isError ? "mdi-alert" : "mdi-check-circle-outline")+" ")]),_c('div',{staticClass:"text-truncate",attrs:{"data-test-id":_vm.isError ? 'errorMsg' : 'successMsg',"title":_vm.message},domProps:{"innerHTML":_vm._s(_vm.displayedMessage)}})],1),_c('div',{staticClass:"message-action"},[_c('v-btn',{attrs:{"text":"","data-test-id":"messageDismissBtn","color":_vm.color},on:{"click":function($event){return _vm.$emit('closed')}}},[_vm._v(" DISMISS ")])],1),_c('div',{staticClass:"v-menu__content--active",style:({
        display: 'none',
        'z-index': _vm.getMaxZIndex(),
      })})])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }