import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store/index.js"
import updateToken from "./assets/js/utilities/keycloak/update-token.js";

Vue.use(VueRouter);

const { isNavigationFailure, NavigationFailureType } = VueRouter;

//ignore duplicate navigation and redirect errors when using programmatic navigation
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((error) => {
        if (!isNavigationFailure(error, NavigationFailureType.duplicated) && !isNavigationFailure(error, NavigationFailureType.redirected)) {
            throw error;
        }
    });
}

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch((error) => {
        if (!isNavigationFailure(error, NavigationFailureType.duplicated) && !isNavigationFailure(error, NavigationFailureType.redirected)) {
            throw error;
        }
    });
}


//Lazy load the route components to improve page load
//https://router.vuejs.org/guide/advanced/lazy-loading.html
const CustomerOverview = () => import("components/customer/CustomerOverview.vue");
const ResellerOverview = () => import("components/reseller/ResellerOverview.vue");
const ProductOverview = () => import("components/products/ProductOverview.vue");
const OrderOverview = () => import("components/orders/OrderOverview.vue");
const ConnectorOverview = () => import("components/connector-hub/connector/ConnectorOverview.vue");
const SpecOverview = () => import("components/connector-hub/spec/SpecOverview.vue");
const ConfigurationEditor = () => import("components/configuration/ConfigurationEditor.vue");
const ProductTypeEditor = () => import("components/product-type/ProductTypeEditor.vue");
const LocalizationOverview = () => import("components/localization/LocalizationOverview.vue");
const LogOverview = () => import("components/logs/LogOverview.vue");
const CartRuleOverview = () => import("components/cart-rule/CartRuleOverview.vue");
const CatalogOverview = () => import("components/catalog/CatalogOverview.vue");
const ServiceProviderOverview = () => import("components/service-provider/ServiceProviderOverview.vue");
const ServiceProviderDetail = () => import("components/service-provider/ServiceProviderDetail.vue");
const ContractOverview = () => import("components/contract/ContractOverview");
const ContractDetail = () => import("components/contract/ContractDetail");
const UserManagement = () => import("components/user-management/UserManagement");
const UserOverview = () => import("components/user-management/user/UserOverview.vue")
const GroupOverview = () => import("components/user-management/group/GroupOverview.vue")

const router = new VueRouter({
    routes: [
        {
            path: "/",
            name: "home",
            component: () => import("@/App.vue")
        },
        {
            path: '/:domain',
            name: "domainHome",
            redirect: { name: 'products' },
        },
        {
            path: "/:domain/products",
            name: "products",
            component: ProductOverview,
            children: [
                {
                    path: ":product",
                    name: "productDetail",
                    component: ProductOverview
                }
            ]
        },
        {
            path: "/:domain/catalogs",
            name: "catalogs",
            component: CatalogOverview,
            children: [
                {
                    path: ":catalog",
                    name: "catalogDetail",
                    component: CatalogOverview
                },
            ]
        },
        {
            path: "/:domain/orders",
            name: "orders",
            component: OrderOverview,
            children: [
                {
                    path: ":order",
                    name: "orderDetail",
                    component: OrderOverview,
                }
            ]
        },
        {
            path: "/:domain/customers",
            name: "customers",
            component: CustomerOverview,
            meta: {
                queryChangeIsRouteChange: false
            },
            children: [
                {
                    path: ":customer",
                    name: "customerDetail",
                    component: CustomerOverview
                }
            ]
        },
        {
            path: "/:domain/resellers",
            name: "resellers",
            component: ResellerOverview,
            children: [
                {
                    path: ":reseller",
                    name: "resellerDetail",
                    component: ResellerOverview
                }
            ]
        },
        {
            path: "/:domain/configuration",
            name: "configuration",
            component: ConfigurationEditor,
            meta: {
                keepParamsOnDomainChange: true
            },
            children: [
                {
                    path: "system",
                    name: "systemConfiguration",
                    redirect: { name: "configuration" },
                    component: {
                        template: '<router-view/>',
                    },
                    children: [
                        {
                            path: ":key",
                            name: "systemConfigEditor",
                            component: ConfigurationEditor
                        },
                    ]
                },
                {
                    path: "product-type/",
                    name: "productType",
                    redirect: { name: 'configuration' },
                    children: [
                        {
                            path: ":productType",
                            name: "productTypeEditor",
                            redirect: { name: 'productTypeData' },
                            children: [
                                {
                                    path: "",
                                    redirect: { name: "productTypeData" }
                                },
                                {
                                    path: "data",
                                    name: "productTypeData",
                                    component: ProductTypeEditor
                                },
                                {
                                    path: "dimensions",
                                    name: "productTypeDimensions",
                                    component: ProductTypeEditor
                                },
                                {
                                    path: "product",
                                    name: "productConfiguration",
                                    component: ProductTypeEditor,
                                    children: [
                                        {
                                            path: "data",
                                            name: "productDataSearch",
                                            component: ProductTypeEditor,
                                            children: [
                                                {
                                                    path: ":pattern",
                                                    name: "productData",
                                                    component: ProductTypeEditor
                                                },
                                            ]
                                        },
                                        {
                                            path: "schema",
                                            name: "productSchemaSearch",
                                            component: ProductTypeEditor,
                                            children: [
                                                {
                                                    path: ":pattern",
                                                    name: "productSchema",
                                                    component: ProductTypeEditor
                                                },
                                            ]
                                        },
                                    ]
                                }
                            ]
                        },
                    ]
                },
                {
                    path: "settings/",
                    name: "settings",
                    redirect: { name: 'configuration' },
                    children: [
                        {
                            path: "cache",
                            name: "cache",
                            component: ConfigurationEditor
                        },
                    ]
                },
            ]
        },

        {
            path: "/:domain/localizations",
            name: "localizations",
            component: LocalizationOverview,
            meta: {
                keepParamsOnDomainChange: true
            },
        },

        {
            path: "/:domain/connectors",
            name: "connectors",
            component: ConnectorOverview,
        },

        {
            path: "/:domain/specs",
            name: "specs",
            component: SpecOverview,
            children: [
                {
                    path: ":spec",
                    name: "specDetail",
                    component: ConnectorOverview
                }
            ]
        },

        {
            path: "/:domain/logs",
            name: "logs",
            component: LogOverview,
        },

        {
            path: "/:domain/cart-rules",
            name: "cartRules",
            component: CartRuleOverview,
            meta: {
                queryChangeIsRouteChange: false
            },
            children: [
                {
                    path: ":rule",
                    name: "cartRuleDetail",
                    component: CartRuleOverview
                }
            ]
        },
        {
            path: "/:domain/service-providers",
            name: "serviceProviders",
            component: ServiceProviderOverview,
            meta: {
                queryChangeIsRouteChange: false
            },
            children: [
                {
                    path: ":provider",
                    name: "serviceProviderDetail",
                    component: ServiceProviderDetail
                }
            ]
        },
        {
            path: "/:domain/user-management",
            name: "userManagement",
            meta: {
                keepParamsOnDomainChange: true,
                queryChangeIsRouteChange: false,
                adminOnly: true,
            },
            component: UserManagement,
            children: [
                {
                    path: "users",
                    name: "users",
                    component: UserOverview,
                    meta: {
                        queryChangeIsRouteChange: false,
                        adminOnly: true
                    },
                    children: [
                        {
                            path: ":user",
                            name: "userDetail",
                            component: UserOverview
                        }
                    ]
                },
                {
                    path: "groups",
                    name: "groups",
                    component: GroupOverview,
                    meta: {
                        queryChangeIsRouteChange: false,
                        adminOnly: true,
                    },
                    children: [
                        {
                            path: ":group",
                            name: "groupDetail",
                            component: GroupOverview
                        }
                    ]
                }
            ]

        },
        {
            path: "/:domain/contracts",
            name: "contracts",
            component: ContractOverview,
            meta: {
                queryChangeIsRouteChange: false
            },
            children: [
                {
                    path: ":contract/:locale",
                    name: "contractDetail",
                    component: ContractDetail
                }
            ]
        },
        {
            //MUST BE LAST
            //--> is used for showing 404 messages 
            path: '*',
            name: "notfound",
            redirect: (to) => {
                store.commit("SET_ERROR", "Error: Page not found");
                const domain = to.params.domain;
                if (domain) {
                    return {
                        name: "domainHome",
                        params: { domain },
                    }
                }
                return { name: "home" };
            }
        }
    ]
});

function isNotExistingDomain(domainId, to) {
    const domains = store?.state?.domains ?? [];
    return domainId && to.name !== "home" && (domains.length === 0 || domains.every(d => d.id !== domainId))
}

function hasRouteChanged(to, from) {
    const isEqual = Vue.prototype.$isEqual;
    if (to?.name !== from?.name) return true;
    if (!isEqual(to?.params, from?.params)) return true;
    if (from?.meta?.queryChangeIsRouteChange && (!isEqual(to?.query, from?.query))) return true;
    return false;
}

router.beforeEach(async function (to, from, next) {
    updateToken();
    if (!Vue.$keycloak.authenticated) {
        const basePath = window.location.toString()
        const redirectUri = basePath.slice(0, -1) + to.path;
        // The page is protected and the user is not authenticated. Force a login.
        await Vue.$keycloak.login({ redirectUri });
        next(false);
        return;
    }

    const domain = to.params.domain;
    const unsavedChanges = !!from.params?.unsavedChanges;
    const routeChanged = hasRouteChanged(to, from);
    if (unsavedChanges && routeChanged) {
        const discardChanges = await Vue.prototype.$confirm("Unsaved changes", "You have unsaved changes, do you want do discard them?");
        if (!discardChanges) {
            next(false);
            return;
        }
        to.params.unsavedChanges = false;
    }

    const isAdmin = await Vue.$keycloak.hasRealmRole("Admin");
    const isUnauthorizedAccess = !isAdmin && to.meta.adminOnly === true;
    const isConnectorPage = to.matched.some(({ name }) => name === "connectors");
    if (isUnauthorizedAccess || (isConnectorPage && domain !== 'system')) {
        next({ name: "notfound", params: { domain } });
        return;
    }

    if (isNotExistingDomain(domain, to)) {
        //if the domain does not exist in the current stored domains, reload
        //all domains once and check again if it exists
        await store.dispatch("loadDomains");
        const domainExists = !isNotExistingDomain(domain, to);
        if (!domainExists) {
            store.commit("SET_ERROR", "Error: Domain with id " + domain + " could not be found");
            next({ name: "home" });
            return;
        }
    }

    next();
});

export default router;