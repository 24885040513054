import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                psblue: '#00a0e3',
                psgreen: '#70b72e',
                slpink: '#e6007e'
            }
        }
    }
});
