import BaseRestClient from '@/assets/js/api/BaseRestClient'
import { parseCapabilities } from '@/assets/js/utilities/es/capabilities.js'
class CoreElasticSearchApiClient extends BaseRestClient {

    constructor(baseURL, errorCallback, successCallback) {
        super(baseURL, "CoreElasticSearchApiClient", errorCallback, successCallback);
    }

    /**
     * @param {*} index The elastic search index from which the capabilities should be loaded 
     * @returns An object with possible headers, filters and sortable fields within given index
     */
    async getCapabilities({ domain, indexType, catalogId, catalogVersion, indexVersion, tableHeaders = [], filters = [], sortableFields = {} }) {

        try {
            //TABLE HEADERS AND POSSIBLE FILTERS
            let queryArray = [];

            if (catalogId) {
                queryArray.push("catalogId=" + catalogId);
            }

            if (catalogVersion !== null && catalogVersion !== undefined) {
                queryArray.push("catalogVersion=" + catalogVersion);
            }

            if (indexVersion !== null && indexVersion !== undefined) {
                queryArray.push("indexVersion=" + indexVersion);
            }

            queryArray.push("fields=*");

            const query = queryArray.join("&");
            const res = await this.request("post", {
                domain,
                url: "/es/" + indexType + "/capabilities",
                query,
                successMsg: null,
                returnErrors: true
            })

            if (!res?.ok) {
                return res;
            }
            const fieldCapabilities = await res.json();
            return parseCapabilities(fieldCapabilities, { tableHeaders, filters, sortableFields });

        } catch (e) {
            console.error(e)
            if (typeof this.errorCallback === 'function') this.errorCallback(e);
            else throw e;
        }
    }

    /**
     * 
     * @param {*} index The elastic search index which should be searched through 
     * @param {*} query The elastic search query
     * @returns 
     */
    async search({ domain, indexType, catalogId, catalogVersion, indexVersion, body, signal, params }) {
        try {
            let result = {};
            let queryArray = [];

            if (catalogId) {
                queryArray.push("catalogId=" + catalogId);
            }

            if (catalogVersion !== null && catalogVersion !== undefined) {
                queryArray.push("catalogVersion=" + catalogVersion);
            }

            if (indexVersion !== null && indexVersion !== undefined) {
                queryArray.push("indexVersion=" + indexVersion);
            }
            const query = queryArray.join("&");
            //ITEMS
            const searchResponse = await this.request("post", {
                domain,
                url: "/es/" + indexType + "/search",
                query,
                body,
                signal,
                successMsg: null,
                returnErrors: true,
                ...params
            });

            if (!searchResponse?.ok && !searchResponse?.aborted) {
                const res = await searchResponse?.json?.();
                if (res?.message) {
                    let errorRes;
                    try {
                        errorRes = JSON.parse(res.message);
                    } catch (e) {
                        throw Error(res.message);
                    }

                    let message = '' + (errorRes.status ?? searchResponse.status);
                    let error = errorRes?.error;
                    while (error?.caused_by) {
                        error = error?.caused_by;
                    }
                    const reason = error?.reason ?? errorRes?.error?.reason;
                    message += ": " + (reason ?? "Error when searching products")
                    throw Error(message);
                }
                throw Error("Loading of products failed: " + searchResponse.status);
            }

            if (searchResponse?.aborted) {
                return searchResponse;
            }

            result.items = [];
            result.total = 0;

            if (searchResponse) {
                const searchResult = await searchResponse.json();
                if (searchResult && searchResult.hits) {
                    result.total = searchResult.hits.total.value;
                    result.items = searchResult.hits.hits;
                }
            }
            return result;
        } catch (e) {
            console.error(e)
            if (typeof this.errorCallback === 'function') this.errorCallback(e);
            else throw e;
        }
    }

};

export default CoreElasticSearchApiClient;