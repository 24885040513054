<template>
  <DialogForm
    :loading="runningAction"
    :title="title"
    :save-btn-label="saveBtnLabel"
    :hide-toolbar="externalDomainCreation"
    :flat="flat"
    close-btn-label="Cancel"
    save-btn-test-id="saveDomainBtn"
    close-btn-test-id="closeDomainDialogBtn"
    data-test-id="domainDialog"
    @close="$emit('close')"
    @save="upsertDomain"
  >
    <template #form="{ loading }">
      <v-form @submit.prevent ref="domainForm">
        <DomainSelector
          v-if="isCreate"
          label="Select parent domain..."
          :value="parentDomainObject"
          :outlined="true"
          :disable-domain-creation="true"
          :set-local="true"
          :rules="[ruleSet.required]"
          :hide-details="false"
          :menu-props="{
            maxHeight: 300,
          }"
          :disabled="disabled || loading"
          class="create-domain-parent"
          @input="({ id }) => (parentDomain = id)"
        />
        <v-text-field
          v-model="domain.id"
          label="Id*"
          outlined
          dense
          data-test-id="domainId"
          class="create-domain-id"
          :disabled="
            (!isCreate && !externalDomainCreation) || disabled || loading
          "
          :rules="[ruleSet.required, domainDuplicateRules.isDuplicateId]"
          @blur="handleIdChange($event)"
        />
        <v-text-field
          v-model="domain.pathPart"
          label="Path part*"
          outlined
          dense
          data-test-id="domainPathPart"
          class="create-domain-path-part"
          :key="domain.id"
          :disabled="disabled || !isCreate || loading"
          :rules="[ruleSet.required, domainDuplicateRules.isDuplicatePath]"
        />

        <v-text-field
          v-model="domain.name"
          label="Name"
          outlined
          dense
          data-test-id="domainName"
          class="create-domain-name"
          :disabled="disabled || loading"
        />
      </v-form>
    </template>
  </DialogForm>
</template>

<script>
import DialogForm from "components/common/templates/DialogForm.vue";
import validationMixin from "mixins/field-rule-validation";
export default {
  name: "Domain",
  mixins: [validationMixin],

  components: {
    DomainSelector: () => import("./DomainSelector"),
    DialogForm,
  },

  props: {
    value: {
      type: Object,
      required: false,
    },

    parent: {
      type: String,
      required: false,
    },

    externalDomainCreation: {
      type: Boolean,
      required: false,
      default: null,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    flat: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      parentDomain: this.parent ?? this.value?.parent,
      domain: this.$cloneObject(this.value) ?? {},
      runningAction: false,
    };
  },

  watch: {
    parent: {
      handler: function (parent) {
        this.parentDomain = this.$cloneObject(parent);
      },
      deep: true,
    },
  },

  methods: {
    handleIdChange(event) {
      if (!this.domain.pathPart || this.domain.pathPart === "") {
        this.$set(this.domain, "pathPart", event.target.value);
      }
    },

    async upsertDomain() {
      const isValid = await this.validate();
      if (isValid) {
        try {
          this.runningAction = true;
          let parentDomain = this.parentDomain;

          if (!parentDomain) {
            //updating an already existring domain, so calculate the path according to the domains full path
            const fullPath = this.domain.fullPath;
            const idx = fullPath.lastIndexOf(".");
            let parts = fullPath.substring(0, idx).trim().split(".");
            if (parts.length > 1) parentDomain = parts[parts.length - 1];
          }

          const successMsg =
            "Domain <strong>" +
            (this.domain?.name ?? this.domain?.pathPart) +
            "</strong> " +
            (this.isCreate ? "created" : "updated");

          const body = {
            pathPart: this.domain.pathPart,
            name: this.domain.name,
          };

          const res = await this.$store.$coreApi.coreDomainApi.upsertDomain(
            parentDomain,
            this.domain.id,
            body,
            {
              successMsg,
            }
          );

          if (!res?.ok) return;

          if (!this.externalDomainCreation) {
            this.domain = {};
            this.$emit("reload");
          } else {
            this.$emit("input", this.domain);
          }
        } finally {
          this.runningAction = false;
        }
      }
    },

    async validate() {
      const form = this.$refs.domainForm;
      if (!this.$validateVForm(form)) return false;
      const domains = await this.$store.$coreApi.coreDomainApi.getDomains();
      return (
        !this.isDuplicateId(this.domain.id, domains) &&
        !this.isDuplicatePath(this.domain.pathPart, domains)
      );
    },

    isDuplicateId(domainId, domains = this.domains) {
      return domains.some(
        ({ id, parentId }) => id === domainId && parentId === this.parentDomain
      );
    },

    isDuplicatePath(pathPart, domains = this.domains) {
      const domainPath = this.getFullPath(pathPart, domains);
      return domains.some(({ fullPath }) => domainPath === fullPath);
    },

    getFullPath(pathPart, domains = this.domains) {
      let parentPath;
      if (this.domain.fullPath) {
        const fullPath = this.domain.fullPath;
        const idx = fullPath.lastIndexOf(".");
        parentPath = fullPath.substring(0, idx);
      } else {
        const parent = domains.find(({ id }) => id === this.parentDomain);
        parentPath = parent?.fullPath ? parent.fullPath + "." : "";
      }
      return parentPath + pathPart;
    },
  },

  computed: {
    title() {
      return this.value?.id
        ? "Update domain " + this.value.id
        : "Create new domain";
    },

    saveBtnLabel() {
      return this.isCreate ? "Create" : "Save";
    },

    isCreate() {
      return this.value?.id === null || this.value?.id === undefined;
    },

    parentDomainObject() {
      if (!this.parentDomain) return null;
      return { id: this.parentDomain };
    },

    domains() {
      return this.$store.state.domains;
    },

    domainDuplicateRules() {
      return {
        isDuplicateId: (value) => {
          if (!value) return true;
          return (
            !this.isDuplicateId(value) ||
            "A domain with this ID already exists as child of " +
              this.parentDomain
          );
        },

        isDuplicatePath: (value) => {
          if (!value) return true;
          return (
            !this.isDuplicatePath(value) ||
            "A domain with this path already exists"
          );
        },
      };
    },
  },
};
</script>

<style scoped>
</style>