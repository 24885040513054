import BaseRestClient from '@/assets/js/api/BaseRestClient'

class ElasticSearchApiClient extends BaseRestClient {

    allowedHeaderTypes = ["boolean", "date", "text", "long", "integer", "short", "byte", "double", "float", "half_float", "scaled_float", "unsigned_long", "_source"];
    languageNames = new Intl.DisplayNames([window.navigator.language], { type: 'language' }); //all countries grouped by language

    constructor(baseURL, errorCallback, successCallback) {
        super(baseURL, "ElasticSearchApiClient", errorCallback, successCallback);
    }

    /**
     * @param {*} index The elastic search index from which the capabilities should be loaded 
     * @returns An object with possible headers, filters and sortable fields within given index
     */
    async getCapabilities({ index, tableHeaders = [], filters = [], sortableFields = {} }) {

        try {
            let result = {};
            //ES does only support lowercase indexes
            index = index.toLowerCase();

            //TABLE HEADERS AND POSSIBLE FILTERS
            const res = await this.request("post", {
                url: index + "/_field_caps?fields=*",
                successMsg: null,
                returnErrors: true
            });

            if (!res?.ok) {
                if (!res) throw Error("Could not load capabilities");
                const errorRes = await res.json();
                let message = errorRes.status ?? fieldCapabilities.status;

                let error = errorRes?.error;
                while (error?.caused_by) {
                    error = error?.caused_by;
                }

                const reason = error?.reason ?? errorRes?.error?.reason;
                message += " - " + (reason ?? "Could not load capabilities")
                throw Error(message);
            }
            const fieldCapabilities = await res.json();
            const fields = fieldCapabilities.fields;
            let languageHeaders = {};

            if (fields) {
                Object.keys(fields).forEach(key => {
                    const field = fields[key];
                    const fieldType = Object.keys(field)[0];

                    if (sortableFields[field]) return;
                    if (key.includes(".")) {
                        //parse object headers
                        const parts = key.split(".");
                        const label = parts[0];
                        const lang = parts[1];
                        //check if the header value is language dependent e.g. a label
                        if (lang.length === 2 && lang != "id" && this.languageNames.of(lang) != lang) {
                            languageHeaders[label] = Object.assign({}, languageHeaders[label], {
                                [lang]: {
                                    searchable: field[fieldType].searchable
                                },
                            });

                            return;
                        }
                    }

                    if (!key.includes("keyword") && this.allowedHeaderTypes.includes(fieldType)) {
                        const properties = field[fieldType];
                        const type = properties.type;
                        //HEADERS
                        const value = type === "_source" ? key : "_source." + key
                        tableHeaders.push({
                            text: key,
                            value,
                            sortable: type !== "_source",
                            type
                        });

                        //FILTERS
                        if (properties.searchable) {
                            let filter = {
                                text: key,
                                property: key,
                                type,
                            };
                            if (type === "date") {
                                filter.parseToUTC = true;
                            }

                            filters.push(filter);
                        }

                        //SORTABLE
                        sortableFields[key] = { type }
                    }
                });

                //add language dependent fields to the headers and filters
                Object.keys(languageHeaders).forEach(key => {
                    const header = languageHeaders[key];
                    const userLanguage = window.navigator.language.split("-")[0];
                    let lang = Object.keys(header)[0];

                    if (header[userLanguage]) {
                        //use the label in the users language, if available
                        lang = userLanguage;
                    }

                    tableHeaders.push({
                        text: key,
                        value: "_source." + key + "." + lang,
                        sortable: false
                    });

                    /* 
                    //TODO: Check if language dependent filters are useful.
                    //--> May lead to problems with different browser languages when sharing a deep link
                    if(header[lang].searchable){
                        filters.push({text: key, property: key + "." + lang, type: "text" });
                    }
                    */
                });
            }

            result.headers = tableHeaders;
            result.filters = filters;
            result.sortableFields = sortableFields;

            return result;

        } catch (e) {
            console.error(e)
            if (typeof this.errorCallback === 'function') this.errorCallback(e);
            else throw e;
        }
    }

    /**
     * 
     * @param {*} index The elastic search index which should be searched through 
     * @param {*} query The elastic search query
     * @returns 
     */
    async search({ index, query, signal, params }) {
        try {
            let result = {};
            //ES does only support lowercase indexes
            index = index.toLowerCase();

            //ITEMS
            const searchResponse = await this.request("post", {
                url: index + "/_search",
                successMsg: null,
                returnErrors: true,
                body: query,
                signal,
                ...params
            });

            if (searchResponse?.aborted) return searchResponse;
            if (!searchResponse?.ok) {
                if (!searchResponse) throw Error("Error when searching products")
                const errorRes = await searchResponse.json();
                let message = errorRes.status ?? searchResponse.status;

                let error = errorRes?.error;
                while (error?.caused_by) {
                    error = error?.caused_by;
                }

                const reason = error?.reason ?? errorRes?.error?.reason;
                message += ": " + (reason ?? "Error when searching products")
                throw Error(message);
            }

            result.items = [];
            result.total = 0;

            if (searchResponse) {
                const searchResult = await searchResponse.json();
                if (searchResult && searchResult.hits) {
                    result.total = searchResult.hits.total.value;
                    result.items = searchResult.hits.hits;
                }
            }
            return result;
        } catch (e) {
            console.error(e)
            if (typeof this.errorCallback === 'function') this.errorCallback(e);
            else throw e;
        }
    }

};

export default ElasticSearchApiClient;