import BaseRestClient from '@/assets/js/api/BaseRestClient'

class CoreCartRuleApiClient extends BaseRestClient {
    constructor(baseURL, errorCallback, successCallback) {
        super(baseURL, "CoreCartRuleApiClient", errorCallback, successCallback);
    }

    async getRules(domain) {
        return this.request("get", {
            domain,
            url: "/rule"
        });
    }

    async getRule(domain, ruleId, params = {}) {
        return this.request("get", {
            domain,
            url: "/rule/" + ruleId,
            ...params
        });
    }

    async upsertRule(domain, rule, successMsg, options) {
        return this.request("put", {
            domain,
            url: "/rule/" + rule.id,
            body: rule,
            successMsg: successMsg ?? "Cart rule " + rule.id + " updated",
            ...options
        });
    }

    async deleteRule(domain, ruleId, successMsg) {
        return this.request("delete", {
            domain,
            url: "/rule/" + ruleId,
            successMsg: successMsg ?? "Cart rule " + ruleId + " deleted"
        });
    }

    async getActionTypes(domain) {
        return this.request("get", {
            domain,
            url: "/rule/action/types"
        });
    }

    async getConditionTypes(domain) {
        return this.request("get", {
            domain,
            url: "/rule/condition/types"
        });
    }

}

export default CoreCartRuleApiClient;