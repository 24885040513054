var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DialogForm',{attrs:{"loading":_vm.runningAction,"title":_vm.title,"save-btn-label":_vm.saveBtnLabel,"hide-toolbar":_vm.externalDomainCreation,"flat":_vm.flat,"close-btn-label":"Cancel","save-btn-test-id":"saveDomainBtn","close-btn-test-id":"closeDomainDialogBtn","data-test-id":"domainDialog"},on:{"close":function($event){return _vm.$emit('close')},"save":_vm.upsertDomain},scopedSlots:_vm._u([{key:"form",fn:function(ref){
var loading = ref.loading;
return [_c('v-form',{ref:"domainForm",on:{"submit":function($event){$event.preventDefault();}}},[(_vm.isCreate)?_c('DomainSelector',{staticClass:"create-domain-parent",attrs:{"label":"Select parent domain...","value":_vm.parentDomainObject,"outlined":true,"disable-domain-creation":true,"set-local":true,"rules":[_vm.ruleSet.required],"hide-details":false,"menu-props":{
          maxHeight: 300,
        },"disabled":_vm.disabled || loading},on:{"input":function (ref) {
                var id = ref.id;

                return (_vm.parentDomain = id);
}}}):_vm._e(),_c('v-text-field',{staticClass:"create-domain-id",attrs:{"label":"Id*","outlined":"","dense":"","data-test-id":"domainId","disabled":(!_vm.isCreate && !_vm.externalDomainCreation) || _vm.disabled || loading,"rules":[_vm.ruleSet.required, _vm.domainDuplicateRules.isDuplicateId]},on:{"blur":function($event){return _vm.handleIdChange($event)}},model:{value:(_vm.domain.id),callback:function ($$v) {_vm.$set(_vm.domain, "id", $$v)},expression:"domain.id"}}),_c('v-text-field',{key:_vm.domain.id,staticClass:"create-domain-path-part",attrs:{"label":"Path part*","outlined":"","dense":"","data-test-id":"domainPathPart","disabled":_vm.disabled || !_vm.isCreate || loading,"rules":[_vm.ruleSet.required, _vm.domainDuplicateRules.isDuplicatePath]},model:{value:(_vm.domain.pathPart),callback:function ($$v) {_vm.$set(_vm.domain, "pathPart", $$v)},expression:"domain.pathPart"}}),_c('v-text-field',{staticClass:"create-domain-name",attrs:{"label":"Name","outlined":"","dense":"","data-test-id":"domainName","disabled":_vm.disabled || loading},model:{value:(_vm.domain.name),callback:function ($$v) {_vm.$set(_vm.domain, "name", $$v)},expression:"domain.name"}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }