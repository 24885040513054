//Global constants

//BASE_CONFIGURATION is declared in config.js file in the public folder 
//eslint-disable-next-line
const config = BASE_CONFIGURATION;

export default {
    install(Vue) {
        Vue.prototype.$coreDomain = config.coreDomain;

        //Vuetify component dimensions
        Vue.prototype.$vToolbarHeightDense = 48;
        Vue.prototype.$vToolbarMarginBottom = 12;
    }
}