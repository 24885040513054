import BaseRestClient from '@/assets/js/api/BaseRestClient'

class CoreCustomerApiClient extends BaseRestClient {
    constructor(baseURL, errorCallback, successCallback) {
        super(baseURL, "CoreCustomerApiClient", errorCallback, successCallback);
    }

    async searchCustomers(domain, body, signal, params = {}) {
        return await this.request("post", {
            url: domain + "/customers",
            body,
            signal,
            successMsg: null,
            ...params
        });
    }

    async getCustomer(domain, customerId) {
        return await this.request("get", {
            url: domain + "/customer/" + customerId,
        })
    }

    async getCustomerGdpr(domain, customerId) {
        return await this.request("get", {
            url: domain + "/customer/" + customerId + "/gdpr",
        })
    }

    async upsertCustomer(domain, customer) {
        customer.isCreatedByAdminUI = true;
        return await this.request("put", {
            url: domain + "/customer/" + customer.id,
            body: customer,
            successMsg: "Customer " + customer.firstname + " " + customer.lastname + " updated",
        })
    }

    async createCustomer(domain, customer) {
        customer.isCreatedByAdminUI = true;
        return await this.request("post", {
            url: domain + "/customer",
            body: customer,
            successMsg: "Customer " + customer.firstname + " " + customer.lastname + " created",
        })
    }

    async deleteCustomer(domain, customer) {
        return await this.request("delete", {
            url: domain + "/customer/" + customer.id,
            successMsg: "Customer " + customer.firstname + " " + customer.lastname + " deleted",
        })
    }

    async getAddresses(domain, customerId) {
        return await this.request("get", {
            url: domain + "/customer/" + customerId + "/address"
        });
    }

    async upsertAddress(domain, customerId, address, params = {}) {
        address.isCreatedByAdminUI = true;
        return await this.request("put", {
            url: domain + "/customer/" + customerId + "/address/" + address.id,
            body: address,
            successMsg: "Address updated",
            ...params
        })
    }

    async deleteAddress(domain, customerId, addressId) {
        return await this.request("delete", {
            url: domain + "/customer/" + customerId + "/address/" + addressId,
            successMsg: "Address deleted",
        });
    }
}

export default CoreCustomerApiClient;