export default {
    extensions: {
        configuration: {
            //New keys for configuration menu or special rendering for existing ones
            keys: [
                /*
                    --- Item Schema ---
                    keySpace: <String> / required / The keySpace to which this key belongs,
                    keyPattern: <String> / required / the key pattern seperated by ".",
                    dataEditable: <Boolean> / required / Specifies if the data of the key pattern is editable,
                    schemaEditable: <Boolean> / required / Specifies if the schema of the key pattern is editable,
                    component: <Function> / required / A function which returns the component for this key pattern,
                    componentParameters: <Object> / optional / The properties of this object are passed as props to the specified component
                    route: <String> / optional / The name of the used route,
                    hideChildren: <Boolean> / optional / If true, the children of the specified key pattern are hidden in the tree,
                */
                {
                    keySpace: "Configuration",
                    keyPattern: "core.payment",
                    route: "paymentConfigEditor",
                    hideChildren: true,
                    dataEditable: true,
                    schemaEditable: false,
                    component: () => import("components/payment/PaymentConfigEditor.vue"),
                },
            ]
        }
    },

    //New routes which should be added to Vue Router. ATTENTION: Existing ones get overwritten if they have the same name and parent
    routes: [
        /*
            --- Item Schema ---
            parentRoute: <String> / optional / The name of the parent route under which 
            route: <VueRouteObject> / required / A vue router object
        */
        {
            parentRoute: "systemConfiguration",
            route: {
                path: "payment",
                name: "paymentConfigEditor",
                redirect: { name: "paymentProviders" },
                component: {
                    template: '<router-view/>',
                },
                children: [
                    {
                        path: "",
                        redirect: { name: "paymentProviders" }
                    },
                    {
                        path: "providers",
                        name: "paymentProviders",
                        component: () => import("components/payment/provider/PaymentProviderOverview.vue")
                    },
                    {
                        path: "merchants",
                        name: "paymentMerchants",
                        component: () => import("components/payment/merchant/PaymentMerchantOverview.vue"),
                    },
                    {
                        path: "methods",
                        name: "paymentMethods",
                        component: () => import("components/payment/method/PaymentMethodEditor.vue"),
                    },
                ]
            },
        }
    ]
}