import BaseRestClient from '@/assets/js/api/BaseRestClient'

class CoreCatalogApiClient extends BaseRestClient {
    constructor(baseURL, errorCallback, successCallback) {
        super(baseURL, "CoreCatalogApiClient", errorCallback, successCallback);
    }

    async getCatalogs(domain, returnErrors) {
        return this.request("get", {
            domain,
            url: "/catalog",
            returnErrors
        })
    }

    async getCatalog(domain, id, returnErrors) {
        return this.request("get", {
            domain,
            url: "/catalog/" + id,
            returnErrors
        })
    }

    async updateCatalog(domain, catalog, successMsg) {
        return this.request("put", {
            url: "/catalog/" + catalog.id,
            body: catalog,
            successMsg: successMsg !== undefined ? successMsg : "Catalog " + catalog.name + " updated",
            domain
        })
    }

    async deleteCatalog(domain, catalog) {
        return this.request("delete", {
            url: "/catalog/" + catalog.id,
            successMsg: "Catalog " + catalog.name + " deleted",
            domain
        });
    }

    async getFilterRules(domain, returnErrors) {
        return this.request("get", {
            domain,
            url: "/filter",
            returnErrors
        })
    }

    async getFilterRule(domain, id, returnErrors) {
        return this.request("get", {
            domain,
            url: "/filter/" + id,
            returnErrors
        })
    }

    async updateFilterRule(domain, rule, successMsg) {
        return this.request("put", {
            domain,
            url: "/filter/" + rule.id,
            body: rule,
            successMsg: successMsg ?? "Filter rule " + rule.id + " updated"
        })
    }

    async deleteFilterRule(domain, id, returnErrors) {
        return this.request("delete", {
            domain,
            url: "/filter/" + id,
            returnErrors,
            successMsg: "Filter rule " + id + " deleted"
        })
    }

    async getSharingRules(domain, returnErrors) {
        return this.request("get", {
            domain,
            url: "/sharing",
            returnErrors
        })
    }

    async getSharingRule(domain, id, returnErrors) {
        return this.request("get", {
            domain,
            url: "/sharing/" + id,
            returnErrors
        })
    }

    async updateSharingRule(domain, rule, successMsg) {
        return this.request("put", {
            domain,
            url: "/sharing/" + rule.id,
            body: rule,
            successMsg: successMsg ?? "Sharing rule " + rule.id + " updated"
        })
    }

    async deleteSharingRule(domain, id, returnErrors) {
        return this.request("delete", {
            domain,
            url: "/sharing/" + id,
            returnErrors,
            successMsg: "Sharing rule " + id + " deleted"
        })
    }

    async scheduleIndex(domain, severity = "important", catalogId, params = {}) {
        let query = "severity=" + severity;
        let successMsg = "Scheduled indexing";
        if (catalogId) {
            query += "&id=" + catalogId;
            successMsg += " of " + catalogId
        }
        return this.request("post", {
            url: domain + "/catalog/scheduleIndex",
            query,
            successMsg,
            ...params
        });
    }

}

export default CoreCatalogApiClient;